/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent {
  translations: any;
  months: Array<string> = [];
  years: Array<number> = [];

  selectedLanguage: string;
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param dialogRef
   * @param service
   */
  constructor(
    public dialogRef: MatDialogRef<AddCardComponent>,
    private service: GlobalService,
    @Inject(MAT_DIALOG_DATA) public cardData: any
  ) {
    // find years close to current year by a decade and push them in the dropdown list
    const nowYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(nowYear + i);
    }

    this.translations = this.cardData.translations;
    this.months = this.cardData.translations.months;

    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * Get fields to add card
   *
   * @param field1
   * @param field2
   * @param field3
   * @param field4
   * @param month
   * @param year
   * @param cardholder
   * @param cvc
   */
  addCard(field1: string, field2: string, field3: string, field4: string, month: string, year: string, cardholder: string, cvc: string) {
    console.log(field1 + ' ' + field2 + ' ' + field3 + ' ' + field4 + ' ' + month + ' ' + year + ' ' + cardholder + ' ' + cvc);
    this.dialogRef.close();
  }
}
