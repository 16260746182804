<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{ translations.scratch_code }}
  </mat-expansion-panel-header>
  <mat-panel-description>
    <form [formGroup]="scratchCode" style="width: 100% !important">
      <div class="container">
        <div class="myContainer">
          <div class="row">
            <div class="col-8">
              <mat-form-field class="w-100">
                <input
                  matInput
                  #scratchCodeInputField
                  placeholder="XXXXXXXXXXXX"
                  maxlength="12"
                  type="text"
                  formControlName="scratchCodeInput"
                  required
                />
                <mat-hint align="end">{{ scratchCodeInputField.value?.length || 0 }}/12</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-4">
              <button
                mat-raised-button
                class="w-100"
                color="accent"
                [disabled]="scratchCode.status !== 'VALID'"
                (click)="sendScratchCode(scratchCode.value)"
              >
                {{ translations.deposit }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-panel-description>
</mat-expansion-panel>
