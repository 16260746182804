import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringSlice',
})
export class MessageFilterPipe implements PipeTransform {
  transform(value: string, limit: number = 8, completeWords = false, ellipsis: string = '..') {
    if (completeWords) {
      limit = value.substr(0, 13).lastIndexOf(' ');
    }
    if (value.length > limit) {
      return value.substr(0, limit) + ellipsis;
    }
    return value.substr(0, limit);
  }
}
