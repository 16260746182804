<mat-toolbar color="accent">
  <mat-toolbar-row>
    <h4 class="positionTitle">{{ translations.page_not_found }}</h4>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card role="group">
  <mat-card-content>
    <p>
      <span>E</span>
      <span>r</span>
      <span>r</span>
      <span>o</span>
      <span>r</span>
      <br />
      <span>4</span>
      <span>0</span>
      <span>4</span>
    </p>
    <p>{{ translations.error_text }}</p>
    <button mat-raised-button color="primary" (click)="goBack()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
