/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-game-room',
  templateUrl: './game-room.component.html',
  styleUrls: ['./game-room.component.scss'],
})
export class GameRoomComponent implements OnInit, OnDestroy {
  // Documentation: http://idangero.us/swiper/demos/
  public bannerConfig: any = {
    pagination: '.swiper-pagination',
    paginationClickable: true,
    dynamicBullets: true,
    speed: 1000,
    autoplay: 2000,
    preloadImages: false,
    loadPrevNext: true,
    lazy: true,
  };

  selectedLanguage: string;

  // selected game paramaters
  selectedGameTables: Array<any> = [{ live: 'N/A', min: 'N/A', max: 'N/A', tableNumber: 'N/A' }];

  // observable for selected game / lottery in url
  selectedGame: string;
  selectedGameId: number;

  // page translations
  translations: any = { table_name: 'N/A', min_bet: 'N/A', max_bet: 'N/A', error_info: 'N/A' };

  // banner parameters
  banners: Array<any> = [];

  private alive: boolean = true;
  private infoObservable: any;

  /**
   * Constructor
   *
   * @param snackBar
   * @param router
   * @param route
   * @param service
   */
  constructor(
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private service: GlobalService
  ) {}

  /**
   * ngOnInit lifecycle
   */
  ngOnInit() {
    this.selectedLanguage = this.service.languageSelected; // get last stored value from service
    this.service.language$ // detect changes from language change
      .pipe(takeWhile(() => this.alive))
      .subscribe((lang) => {
        this.selectedLanguage = lang;
      });

    // get parameters from url and determine wheteher user has selected game or lottery
    this.route.params.subscribe((params) => {
      if ('game' in params) {
        this.selectedGame = params.game;
      } else if ('lottery in params') {
        this.selectedGame = params.game;
      } else {
        this.router.navigate(['**']);
      }
    });

    this.infoObservable = this.service.infoData$ // detect changes from info call
      .pipe(takeWhile(() => this.alive))
      .subscribe((info) => {
        if (info) {
          const game = info.games.find((x) => x.game_name === this.selectedGame); // get object with game details based on game name from parameters of url
          this.translations = info.translations; // get the translations
          if (game) {
            this.selectedGameTables = game.listOfGameTablesProperties;
            this.banners = game.banners; // get the banners
          } else {
            this.selectRoom(0); // if no listOfGameTablesProperties are provided, redirect to game screen
          }
        } else {
          this.router.navigate(['error-page']);
          this.snackBar.open(this.translations['error_info'], 'OK', { duration: 5000 });
        }
      });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Send to appropriate room for game or lottery
   *
   * @param index
   */
  selectRoom(index: number): void {
    // check if game
    if ('game' === Object.keys(this.route.snapshot.params)[0]) {
      this.service.setGameParametersForIFrame(this.selectedGame, index);
      this.router.navigate(['game-room', this.selectedGame, 'table', index]);
    } else if ('lottery' === Object.keys(this.route.snapshot.params)[0]) {
      // or lottery
      this.service.setGameParametersForIFrame(this.selectedGame, index);
      this.router.navigate(['lottery', this.selectedGame, 'table', index]);
    } else {
      this.snackBar.open(this.translations['error_info'], 'OK', { duration: 5000 });
    }
  }

  selectBanner(index) {
    console.log('selected banner index: ' + index);
  }
}
