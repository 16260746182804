import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from './shared/global-service.service';
import { environment } from './../environments/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  
  serverUrl: string = environment.serverUrl;

  /**
   * We are using this interceptor to place the Authorization header on every request
   */
  constructor(private router: Router, private service: GlobalService, private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.service.checkIfTokenExists()) {
      req = req.clone({
        headers: req.headers.append('Authorization', 'Bearer ' + this.service.getToken()),
      });
    } else {
      if (req.url.includes('/info' )) {
        let reqBody = req.body;
        const httpRequest = new HttpRequest(<any>req.method,  this.serverUrl+'info-prelogin', reqBody);         
        Object.assign(req, httpRequest);
      } 
    }
    return next.handle(req).pipe(
      catchError((response: HttpEvent<any>) => {
        if (response instanceof HttpErrorResponse) {
          if (response.url.includes('/info') && response.status !== 401) {                       
            this.router.navigate(['error-page']);            
          }
          if (response.status === 401) {
            this.service.deleteToken();
            this.service.isLoggedIn = false;            
            if (this.router.url !== '/' && !response.url.includes('/info')) {
              this.router.navigate(['']);
            }  
          }
          // define the notification message is shown when the snackbar opens
          let errorMsg = 'An error occurred';
          if (response instanceof Error || response.error instanceof ErrorEvent) {
            errorMsg += ' client-side'; //client-side error
          } else {
            errorMsg = response.error; //server-side error
          }
          this.snackBar.open(errorMsg, 'OK', { duration: 5000 });
        }
        return throwError(response);
      })
    );
  }
}
