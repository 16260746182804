<mat-card style="height: unset">
  <div id="bannerArea">
    <swiper [config]="bannerConfig" id="bannerSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let banner of banners; let i = index" style="text-align: center" (click)="selectBanner(i)">
          <img data-src="{{ banner.imageUrl }}" alt="{{ banner.title }}" class="banner-image swiper-lazy" />
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-black"></div>
    </swiper>
  </div>
  <div id="gridContainer">
    <div class="item" *ngFor="let table of selectedGameTables; let i = index" (click)="selectRoom(i)">
      <table id="gameRoom-{{ i }}">
        <tr>
          <td colspan="2" cellpadding="5" align="center">
            <strong>{{ translations.table_number }} {{ table.tableNumber }}</strong>
          </td>
        </tr>
        <tr>
          <td style="padding: 10px" width="60%">
            <img src="{{ table.imageUrl }}" alt="{{ table.tableNumber }}" class="thumbnail" />
          </td>
          <td width="40%">
            <table cellpadding="5" width="100%" border="0">
              <tr>
                <td align="left">
                  <em>{{ translations.min }} {{ table.min }}</em>
                </td>
              </tr>
              <tr>
                <td align="left">
                  <em>{{ translations.max }} {{ table.max }}</em>
                </td>
              </tr>
              <tr>
                <td align="right">
                  <img src="../../assets/information.svg" alt="livenow" height="20vh" /><em> {{ table.live }}</em>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</mat-card>
