// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //serverUrl: 'http://onlineplatform.local/',
  //iframeURL: 'http://localhost:8888',  
  
  /////// serverUrl: 'https://platformapi.cosmicpro.net/api/',
  /////// sseUrl: 'https://platformapi.cosmicpro.net/api/',
  /////// iframeURL: 'https://live-roulette.cosmicpro.net',

  //serverUrl: 'http://127.0.0.1:8000/api/',
  //sseUrl: 'http://127.0.0.1:8000/api/',
  //iframeURL: 'http://localhost:8888'

  serverUrl: 'https://platform.fooding.gr/api/',  
  sseUrl: 'https://platform.fooding.gr/api/',
  iframeURL: 'https://roulette.fooding.gr',

};
