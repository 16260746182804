import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnInit, OnDestroy {
  @Input() profileData: any;
  @Input() translations: any;
  selectedLanguage: string;

  userDetails: FormGroup;

  private alive: boolean = true;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder,
    private service: GlobalService
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$
      .pipe(
        takeWhile(() => this.alive),
        filter((lang) => lang !== this.selectedLanguage)
      )
      .subscribe((lang) => {
        this.selectedLanguage = lang;
        this.setPlayerDataFromTranslations();
      });
  }

  /**
   * on init
   */
  ngOnInit() {
    this.setPlayerDataFromTranslations();
  }

  /**
   * Sets player data from translations
   */
  setPlayerDataFromTranslations() {
    this.userDetails = this.fb.group({
      id: [{ value: this.profileData.player_id, disabled: true }, Validators.required],
      username: [{ value: this.profileData.username, disabled: true }, [Validators.required, Validators.maxLength(20)]],
      f_name: [{ value: this.profileData.f_name, disabled: true }, Validators.required],
      l_name: [{ value: this.profileData.l_name, disabled: true }, Validators.required],
      email: [{ value: this.profileData.email, disabled: true }, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+')]],
      passport: [{ value: this.profileData.passport_number, disabled: false }],
      birthdate: [{ value: this.profileData.birthdate, disabled: true }, Validators.required],
      gender: [{ value: this.profileData.gender, disabled: true }, Validators.required],
      country: [{ value: this.profileData.country, disabled: true }, Validators.required],
      city: [{ value: this.profileData.city, disabled: false }, Validators.required],
      address: [{ value: this.profileData.address, disabled: false }],
      phone: [{ value: this.profileData.m_phone, disabled: false }, Validators.required],
    });
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.alive = false;
  }

  // save changes made to profile
  saveChanges() {
    if (this.userDetails.errors == null) {
      const updateDetails = {
        passport: this.userDetails.controls.passport.value ? this.userDetails.controls.passport.value : null,
        country: this.userDetails.controls.country.value,
        city: this.userDetails.controls.city.value,
        address: this.userDetails.controls.address.value,
        phone: this.userDetails.controls.phone.value ? this.userDetails.controls.phone.value : null,
      };

      this.updateProfile(updateDetails);
    }
  }

  // retrieve / update profile
  updateProfile(updateDetails) {
    const body = { lang: this.selectedLanguage, details: updateDetails };

    this.http
      .post(this.service.serverUrl + 'profile-details', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: Response) => {
          let message: string;
          if (res.status === 200) message = this.translations.profile_details_success;
          else message = this.translations.profile_details_error;

          this.snackBar.open(message, 'OK', { duration: 5000 });
        },
        (err: HttpErrorResponse) => {
          this.setPlayerDataFromTranslations(); //reset player's profile details
        }
      );
  }
}
