import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-single-message',
  templateUrl: './single-message.component.html',
  styleUrls: ['./single-message.component.scss'],
})
export class SingleMessageComponent {
  @Input() messageData: any;
  @Input() translationsData: any;

  @Output() deletedMessage: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private router: Router, public snackBar: MatSnackBar, private service: GlobalService) {}

  // delete specified message from server and hide it in front to avoid extra request
  deleteMessage(messageId: number, messageType: 'string') {
    this.http
      .delete(this.service.serverUrl + 'messages' + '/delete/' + messageId, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.deletedMessage.emit({ msgID: messageId, type: messageType });
            this.snackBar.open(this.translationsData.message_delete, 'OK', { duration: 5000 });
          } else {
            this.snackBar.open(res, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {}
      );
  }
}
