/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-bonuses',
  templateUrl: './bonuses.component.html',
  styleUrls: ['./bonuses.component.scss'],
})
export class BonusesComponent implements OnDestroy {
  @Input() bonuses: any;
  @Input() translations: any;

  @Output() bonusSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedLanguage: string;
  alive: boolean = true;

  /**
   * Constructor
   */
  constructor(private http: HttpClient, private service: GlobalService, private router: Router, public snackBar: MatSnackBar) {
    this.selectedLanguage = this.service.languageSelected;
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * clicked on promo
   *
   * @param selectedBonusId
   */
  selectBonus(selectedBonusId: number) {
    if (this.service.checkIfTokenExists()) {
      const body = { bonus_id: selectedBonusId };
      this.http
        .post(this.service.serverUrl + 'bonus', body, {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        })
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.snackBar.open(this.translations.bonuses.success_activation, 'OK', { duration: 5000 });
              this.bonusSelected.emit(true);
            } else this.snackBar.open(res, 'OK', { duration: 5000 });
          },
          (err: HttpErrorResponse) => {}
        );
    } else {
      this.router.navigate(['wallet']);
    }
  }
}
