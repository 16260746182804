<!-- prelogin Banner Area -->
<div class="swipper-container">
  <swiper [config]="gamesConfig" id="gameCarousel">
    <div class="swiper-wrapper">
      <ng-container *ngFor="let game of games; let i = index">
        <div class="swiper-slide" (click)="('')">
          <img src="assets/cashback(mobile).gif" class="swiper-lazy" />
          <!-- <div class="swiper-lazy-preloader"></div> -->
        </div>
        <div class="swiper-slide" (click)="('')">
          <img src="assets/evo(mobile).png" class="swiper-lazy" />
          <!-- <div class="swiper-lazy-preloader"></div> -->
        </div>
        <div class="swiper-slide" (click)="('')">
          <img src="assets/freespin(mobile).gif" class="swiper-lazy" />
          <!-- <div class="swiper-lazy-preloader"></div> -->
        </div>
        <div class="swiper-slide" (click)="('')">
          <img src="assets/netent.jpg" class="swiper-lazy" />
          <!-- <div class="swiper-lazy-preloader"></div> -->
        </div>
        <div class="swiper-slide" (click)="('')">
          <img src="assets/pragmatic(mobile).gif" class="swiper-lazy" />
          <!-- <div class="swiper-lazy-preloader"></div> -->
        </div>
      </ng-container>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination swiper-pagination-blue"></div>
  </swiper>
</div>
<mat-spinner *ngIf="!dataReceived" style="margin: 5vh auto"></mat-spinner>

<div class="col-12" id="upcomingGames">
  <p>Upcoming Games</p>
</div>
<div class="gamesContainer">
  <div class="d-inline-flex p-2" id="casinoGame">
    <img src="assets/casinoGame.png" alt="casinoImg" />
  </div>
  <div class="d-inline-flex p-2" id="lotteryGame">
    <img src="assets/lotteryGame.png" alt="lotteryImg" />
  </div>
  <div class="d-inline-flex p-2" id="roulleteGame">
    <img src="assets/roulleteGame.png" alt="roulleteImg" />
  </div>
</div>

<!-- <swiper *ngIf="dataReceived" [config]="bannerConfig" id="bannerArea">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let banner of banners; let i = index" style="text-align: center" (click)="selectBanner(i)">
        <img data-src="{{ banner.imageUrl }}" alt="{{ banner.title }}" class="banner-image swiper-lazy" />
      </div>
    </div>
  </swiper>
  <mat-spinner *ngIf="!dataReceived" style="margin: 0 auto"></mat-spinner> -->
<!-- Live casino & Lotteries -->
<!-- <mat-tab-group #gamePicker (selectedTabChange)="tabChangedEvent($event)">
    <mat-tab label="{{ translations.live_casino }}">
      <swiper *ngIf="!showSwiper && dataReceived" [config]="gamesConfig" id="gameCarousel">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let game of games; let i = index">
            <div *ngIf="game.game_category === 'live_casino'" class="swiper-slide" (click)="selectGame(i, game.game_name)">
              <table>
                <tr>
                  <td cellpadding="5" align="center">
                    <em>{{ game.game_name }}</em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img data-src="{{ game.imageUrl }}" alt="{{ game.game_name }}" height="130" class="swiper-lazy" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <table cellpadding="5" width="100%" border="0">
                      <tr>
                        <td colspan="2" align="center">{{ translations.top_players }}</td>
                      </tr>
                      <tr *ngFor="let player of game.listOfTopPlayers">
                        <td align="left">{{ player.username }}</td>
                        <td align="right">{{ player.score }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </ng-container>
        </div>
      </swiper>
      <mat-spinner *ngIf="!dataReceived" style="margin: 5vh auto"></mat-spinner>
    </mat-tab>
    <mat-tab label="{{ translations.lotteries }}">
      <swiper *ngIf="showSwiper && dataReceived" [config]="gamesConfig" id="lotteryCarousel">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let lottery of games; let i = index">
            <div *ngIf="lottery.game_category === 'lotteries'" class="swiper-slide" (click)="selectGame(i, lottery.game_name)">
              <table>
                <tr>
                  <td cellpadding="5" align="center">
                    <em>{{ lottery.game_name }}</em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img data-src="{{ lottery.imageUrl }}" alt="{{ lottery.game_name }}" height="130" class="swiper-lazy" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <table cellpadding="5" width="100%" border="0">
                      <tr>
                        <td colspan="2" align="center">{{ translations.top_players }}</td>
                      </tr>
                      <tr *ngFor="let player of lottery.listOfTopPlayers">
                        <td align="left">{{ player.username }}</td>
                        <td align="right">{{ player.score }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </ng-container>
        </div>
      </swiper>
      <mat-spinner *ngIf="!dataReceived" style="margin: 5vh auto"></mat-spinner>
    </mat-tab>
  </mat-tab-group>
</mat-card>-->
