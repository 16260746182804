<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{ translations.credit_depit_card }}
  </mat-expansion-panel-header>
  <mat-panel-description>
    <div class="container">
      <div class="myContainer">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-select [placeholder]="translations.select_card" [(ngModel)]="selectedCard" (selectionChange)="showSelectedCard()">
                <mat-option *ngFor="let card of creditCards.cards; let i = index" [value]="card.id">
                  {{ card.number }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100">
              <input matInput type="number" [placeholder]="translations.amount" [(value)]="creditCards.amountValue" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100">
              <mat-select [placeholder]="translations.currency" [(ngModel)]="selectedCurrency" (selectionChange)="showSelectedCurrency()">
                <mat-option *ngFor="let currency of creditCards.currencies; let i = index" [value]="currency.id">
                  {{ currency.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button mat-raised-button class="w-100" color="accent" (click)="addNewCard()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div class="col-6">
            <button mat-raised-button class="w-100" color="accent" (click)="componentAction()">
              {{ requestType === 'withdraw' ? (translations.withdraw | uppercase) : (translations.deposit | uppercase) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-panel-description>
</mat-expansion-panel>
