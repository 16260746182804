import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'ogp-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss'],
})
export class MainScreenComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = false;

  // Documentation: http://idangero.us/swiper/demos/
  // public bannerConfig: any = {
  //   pagination: {
  //     el: '.swiper-pagination',
  //     dynamicBullets: true,
  //     dynamicMainBullets: 3,
  //   },
  //   paginationClickable: true,
  //   // dynamicBullets: true,
  //   speed: 1000,
  //   preloadImages: true,
  //   loadPrevNext: true,
  //   // lazy: true,
  //   loop: true,
  //   slidesPerView: 1,
  //   centeredSlides: true,
  //   navigation: true,
  // };

  public gamesConfig: any = {
    spaceBetween: 0,
    slidesPerView: 1,
    centeredSlides: true,
    grabCursor: true,
    preloadImages: true,
    followFinger: true,
    loadPrevNext: true,
    // lazy: {
    //   loadPrevNext: true,
    //   loadPrevNextAmount: 0,
    // },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      dynamicMainBullets: 1,
      clickable: false,
      paginationClickable: true,
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      loop: true,
    },
  };

  showSwiper: boolean = false; // flag to render / hide the lottery swiper
  banners: Array<any> = []; // banner parameters
  games: Array<any> = []; // gaming parameters
  translations: any = { live_casino: 'N/A', lotteries: 'N/A', top_players: 'N/A', error_info: 'N/A' }; // page translations
  selectedLanguage: string; // selected game paramaters
  dataReceived: boolean = false; // represents whether the data have been received from the resolver

  private alive: boolean = true;
  private infoObservable: any; // flag for observables

  constructor(private router: Router, private route: ActivatedRoute, private service: GlobalService, public snackBar: MatSnackBar) {}

  ngOnInit() {
    if (this.service.checkIfTokenExists()) this.isLoggedIn = true;

    this.selectedLanguage = this.service.languageSelected; // get last stored value from service
    this.service.language$ // detect changes from language change
      .pipe(takeWhile(() => this.alive))
      .subscribe((lang) => {
        this.selectedLanguage = lang;
      });

    this.infoObservable = this.service.infoData$ // detect changes from info call
      .pipe(takeWhile(() => this.alive))
      .subscribe((info) => {
        this.dataReceived = false;
        if (info) {
          this.banners = info.banners;
          this.games = info.games;
          this.translations = info.translations;
        } else {
          this.router.navigate(['error-page']);
          this.snackBar.open(this.translations.error_info, 'OK', { duration: 5000 });
        }
        this.dataReceived = true;
      });
  }

  ngOnDestroy() {
    this.alive = false;
    this.infoObservable.unsubscribe();
  }

  // set observable for info request from service - app.component
  getInfo() {
    const sub = this.service.infoData$ // detect changes from info call
      .pipe(takeWhile(() => this.alive))
      .subscribe((info) => {
        this.dataReceived = false;
        if (info) {
          this.banners = info.banners;
          this.games = info.games;
          this.translations = info.translations;
        } else {
          this.router.navigate(['error-page']);
          this.snackBar.open(this.translations.error_info, 'OK', { duration: 5000 });
        }
        this.dataReceived = true;
      });

    // unsubscribe from observable
    sub.unsubscribe();
  }

  // banner selection action
  selectBanner(index) {
    console.log('selected banner index: ' + index);
  }

  // game selection action
  selectGame(index: number, gameName: string) {
    console.log('selected: ' + gameName);
    if (gameName) {
      this.service.requestInfoData(this.infoObservable); // unnecessary because delay is needed to change observable value, until game room is inited
      this.router.navigate(['game-room', gameName]);
    }
  }

  // on changing tab hide / show the swipper for proper rendering
  // Probably this works only for two items. If third is added we need to fix it
  tabChangedEvent($event: MatTabChangeEvent) {
    this.showSwiper = !!$event.index;
  }
}
