import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';
import { Location } from '@angular/common';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WalletComponent implements OnInit, OnDestroy {
  loadComponents: boolean = false;
  selectedLanguage: string;
  openDeposit: boolean;
  wallet: any = {
    title: 'N/A',
    transactions: { title: 'N/A' },
    deposit: { title: 'N/A' },
    withdraw: { title: 'N/A' },
    bonuses: { translations: { title: 'N/A', noData: 'N/A' }, notifications: 0, data: [] },
  };
  translations: any;
  currentRequests: any;
  currencyRequest: string = 'N/A';

  private alive: boolean = true;

  constructor(private http: HttpClient, private router: Router, private service: GlobalService, private location: Location) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$
      .pipe(
        takeWhile(() => this.alive),
        filter((lang) => lang !== this.selectedLanguage)
      )
      .subscribe((lang) => {
        this.selectedLanguage = lang;
        this.walletRequest();
      });
  }

  ngOnInit() {
    this.openDeposit = this.service.isDeposit;
    this.walletRequest();
  }

  walletRequest() {
    const body = { lang: this.selectedLanguage };

    this.http
      .post(this.service.serverUrl + 'wallet', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            // get translations for headers
            this.wallet.transactions.title = res.data.translations.wallet.transactions_history;
            this.wallet.deposit.title = res.data.translations.wallet.deposit;
            this.wallet.withdraw.title = res.data.translations.wallet.withdraw;
            this.wallet.bonuses.translations.title = res.data.translations.wallet.bonuses.title;
            this.wallet.bonuses.translations.noData = res.data.translations.wallet.bonuses.no_data;
            this.wallet.title = res.data.translations.wallet.wallet;

            this.translations = res.data.translations.wallet;

            // get data for bonuses
            this.wallet.bonuses.notifications = res.data.bonuses_count;
            this.wallet.bonuses.data = res.data.bonuses;
            this.loadComponents = true;

            // get data for accounting requests
            this.currentRequests = res.data.accounting_requests;
            this.currencyRequest = res.data.wallet.currency;
          } else {
            this.service.isLoggedIn = false;
            this.loadComponents = false;
            this.router.navigate(['']);
          }
        },
        (err: HttpErrorResponse) => {
          this.loadComponents = false;
          if (err instanceof Error || err.error instanceof ErrorEvent) {
            //redirect to error-page in case of a client-side error
            this.router.navigate(['error-page']);
          } else {
            // redirect to homepage for any server-side error except of a 401 Not Authorized error
            if (err.status !== 401) {
              this.router.navigate(['']);
            }
          }
        }
      );
  }

  // reset expanded panel to default
  ngOnDestroy() {
    this.alive = false;
    this.openDeposit = false;
    this.service.isDeposit = false;
  }

  /**
   * Receive event that bonus has been activated to reduce the notification counter
   *
   * @param $event
   */
  receiveBonusSelected($event: boolean): void {
    if ($event) this.wallet.bonuses.notifications--;
  }

  goBack(): void {
    this.location.back();
  }
}
