import { addYears } from 'date-fns';
/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

import { Component, OnInit, OnDestroy, HostListener, EventEmitter, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

// services
import { GlobalService } from './shared/global-service.service';
import { CookieService } from 'ngx-cookie-service';
import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js';
import { NotifierService } from 'angular-notifier';
import { finalize } from 'rxjs/operators';
import { hide } from '@popperjs/core';
import { environment } from '../environments/environment';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ogp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  formData: any = [];

  isLoggedIn: boolean = false;
  promptLogin: boolean = false;
  loginFailed: boolean = false;
  isMenuOpen: boolean = false;
  isGameMenuOpen: boolean = false;
  bonusWalletExists: boolean = false;
  toggleLogin: boolean = false;
  toggleJoin: boolean = false;
  isAjaxPollingCalled: boolean = false;

  ajaxPollingInterval: any;

  themeSelected: string = 'blue-theme';
  languageSelected: string = 'EN';

  notifications: any = {
    promotions: 0,
    bonuses: 0,
    messages: 0,
    player_id: 0,
    player_username: 'N/A',
    fulfillment_amount: '0',
    balance_amount: 0,
    total_balance: '0',
    withdrawable_amount: '0',
  };

  translations: any = {
    about: 'N/A',
    accounting_settings: 'N/A',
    arabic: 'N/A',
    bonus_wallet_balance: 'N/A',
    bonus_change: 'N/A',
    promotions_change: 'N/A',
    dark_pink: 'N/A',
    dark_purple: 'N/A',
    light_blue: 'N/A',
    deposit: 'N/A',
    deposit_to_your_wallet: 'N/A',
    english: 'N/A',
    create_account: 'N/A',
    forgot_password: 'N/A',
    gaming_history: 'N/A',
    id: 'N/A',
    messages: 'N/A',
    login: 'N/A',
    password: 'N/A',
    play: 'N/A',
    please_login: 'N/A',
    promotions: 'N/A',
    remember_me: 'N/A',
    total_balance: 'N/A',
    username: 'N/A',
    wallet: 'N/A',
    withdrawable: 'N/A',
    theme: 'N/A',
    wallet_change: 'N/A',
    expires_at: 'N/A',
    message_change: 'N/A',
    error_info: 'N/A',
    live_casino: 'N/A',
    lotteries: 'N/A',
    live_roulette: 'N/A',
  };

  protected eventSource: any; // reference to an EventSource to process SSE
  private readonly notifier: NotifierService;
  private isSSEcalled: boolean = false; // ensure that the SSE is called only once

  constructor(
    private http: HttpClient,
    private router: Router,
    private service: GlobalService,
    private cookieService: CookieService,
    private snackBar: MatSnackBar,
    private notifierService: NotifierService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.notifier = notifierService;
    this.translations.login = 'test';
    // observe if user is logged in, otherwise prompt him to login
    this.service.promptLogin$.subscribe((login) => {
      this.promptLogin = !login;
      this.isLoggedIn = login;

      // initiate listening for server events
      if (login && !this.isSSEcalled) {
        this.subscribeToSSE();
      }

      // if (!login && router.url === '/') {
      //   this.toggleMenu();
      //   this.doToggleLogin();
      // }
      if (router.url === '/error-page' && this.isMenuOpen) {
        this.toggleMenu();
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: Event) {
    this.closeSSE();
    $event.preventDefault(); // We call this so that no alert is displayed to the user
  }

  /**
   * Angular lifecycle method - on init
   */
  public ngOnInit(): void {
    const menuDropdown = document.getElementById('menuDropdown');
    const gameMenuDropdown = document.getElementById('gameMenuDropdown');
    const languageDropdown = document.getElementById('languageSelector');
    const gameMenuButton = document.getElementById('gameMenuButton');

    const darkenClassElements = document.querySelectorAll<HTMLElement>('.darken');
    const darken2ClassElements = document.querySelectorAll<HTMLElement>('.darken2');
    const darkenClassElementsLength = darkenClassElements.length;
    const darken2ClassElementsLength = darken2ClassElements.length;

    //when language dropdown is opened, close other dropdown menus
    languageDropdown.addEventListener('show.bs.dropdown', () => {
      if (this.isMenuOpen) {
        this.toggleMenu();
      } else if (this.isGameMenuOpen) {
        this.toggleGameMenu();
      }
    });

    menuDropdown.addEventListener('show.bs.collapse', () => {
      this.isMenuOpen = true;
      // when dropdown menu is opened, show dark background
      for (let i = 0; i < darkenClassElementsLength; i++) {
        const element = darkenClassElements.item(i);
        element.style.display = 'unset';
      }
      //Close Game Menu if it's open
      if (this.isGameMenuOpen) {
        this.toggleGameMenu();
      }
    });

    menuDropdown.addEventListener('hide.bs.collapse', () => {
      this.isMenuOpen = false;
      // when dropdown menu is closed again, hide dark background
      for (let i = 0; i < darkenClassElementsLength; i++) {
        const element = darkenClassElements.item(i);
        element.style.display = 'none';
      }

      //Change text on Buttons if they're X to their original
      if (this.toggleLogin === true) {
        this.doToggleLogin();
      }
    });

    gameMenuDropdown.addEventListener('show.bs.collapse', () => {
      this.isGameMenuOpen = true;
      //change gameMenu button's icon from 3 horizontal lines to X
      gameMenuButton.classList.add('opened');
      gameMenuButton.setAttribute('aria-expanded', 'true');
      // when dropdown menu is opened, show dark background
      for (let i = 0; i < darken2ClassElementsLength; i++) {
        const element = darken2ClassElements.item(i);
        element.style.display = 'unset';
      }
      // Close Main Menu if it's open
      if (this.isMenuOpen) {
        this.toggleMenu();
      }
    });

    gameMenuDropdown.addEventListener('hide.bs.collapse', () => {
      this.isGameMenuOpen = false;
      //change gameMenu button's icon from X to 3 horizontal lines
      gameMenuButton.classList.remove('opened');
      gameMenuButton.setAttribute('aria-expanded', 'false');
      // when dropdown menu is closed again, hide dark background
      for (let i = 0; i < darken2ClassElementsLength; i++) {
        const element = darken2ClassElements.item(i);
        element.style.display = 'none';
      }
    });

    this.getStoredThemeFromCookie();
    this.getStoredLanguageFromCookie();

    // Feature to enable navigating with Browser's back arrows and going to previous components
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.ngZone.run(() => {
        this.cd.detectChanges();
      });
    });
  }

  /**
   * Angular lifecycle method - on destroy
   */
  public ngOnDestroy(): void {
    this.closeSSE();
  }

  /**
   * toggle menu panel
   */
  public toggleMenu(): void {
    const menuButton = document.getElementById('menuButton');
    menuButton.click();
    this.doToggleLogin();

    //Change text on Buttons if they're X to their original
    if (this.toggleLogin === false && this.isMenuOpen === true) {
      this.doToggleLogin();
    }
  }

  public toggleGameMenu(): void {
    const gameMenuButton = document.getElementById('gameMenuButton');
    gameMenuButton.click();

    //Change text on Buttons if they're X to their original
    if (this.toggleLogin === true && this.isMenuOpen === false) {
      this.doToggleLogin();
    }
  }

  /**
   * Change text on Button Login to X
   */
  public doToggleLogin(): void {
    this.toggleLogin = !this.toggleLogin;
  }

  /**
   * Close open Menus when user clicks Join
   */
  public doToggleJoin(): void {
    if (this.isMenuOpen) {
      this.toggleMenu();
    } else if (this.isGameMenuOpen) {
      this.toggleGameMenu();
    }
  }

  /**
   * Login request function
   */
  public login() {
    this.loginFailed = false;

    if (this.formData.username && this.formData.password) {
      // if fields not empty then make request
      const body = {
        username: this.formData.username,
        password: this.formData.password,
      };

      this.http
        .post(this.service.serverUrl + 'login', JSON.stringify(body), {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        })
        .pipe(finalize(() => this.service.requestLoginStatus(this.isLoggedIn)))
        .subscribe(
          (res: Response) => {
            if (res.status === 200) {
              this.isLoggedIn = true;
              this.loginFailed = false;
              this.languageSelected = res['data'].language.slice(0, 2).toUpperCase(); // set language as the one stored from  signup
              if (this.formData.remember) {
                localStorage.setItem('token', res['data'].token);
              } else {
                sessionStorage.setItem('token', res['data'].token);
              }

              //Add the token as a query parameter to the iframe URL
              const token = res['data'].token;
              console.log(token);

              //Append the token to the iframe URL
              environment.iframeURL += '?id=' + token;

              // initiate listening for server events
              if (!this.isSSEcalled) {
                this.subscribeToSSE();
              }

              this.getInfo();
            } else {
              this.resetAuthVariables();
            }

            this.toggleMenu();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.loginFailed = true;
            }
            this.resetAuthVariables();
          }
        );
    }
  }

  /**
   * get main page data (banners, wallet, promotions, bonuses, messages, games, lotteries, player details)
   */
  public getInfo(): void {
    const body = { lang: this.languageSelected };

    this.http
      .post(this.service.serverUrl + 'info', JSON.stringify(body), {
        headers: new HttpHeaders(),
      })
      .pipe(finalize(() => this.service.requestLoginStatus(this.isLoggedIn)))
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.isLoggedIn = res.data.loggedIn;
            if (!res.data.prelogin && this.isLoggedIn) {
              this.service.requestLanguage(this.languageSelected); // pass it to the service and across the app
            }
            if (this.isLoggedIn && 'wallet' in res.data) {
              // this.notifications.total_balance = res.data.wallet.deposit_amount; // gets the total balance amount shown in upper left corner
              this.notifications.total_balance = res.data.wallet.balance + ' ' + res.data.wallet.currency;
              this.notifications.withdrawable_amount = res.data.wallet.withdrawable_amount; // gets the withdrawble amount shown in the loggedin menu
              this.notifications.player_id = res.data.wallet.player_id; // gets the player's id shown in the loggedin menu
              this.notifications.player_username = res.data.username; // gets the player's username shown in the loggedin menu
              // check if bonus wallet exists & pass the balance amount and fulfillment amount to the tooltip
              if ('bonus_wallet' in res.data.wallet) {
                this.bonusWalletExists = true; // show indication that bonus wallet exists and area is clickable
                this.notifications.fulfillment_amount = res.data.wallet.bonus_wallet.fulfillment_amount;
                this.notifications.balance_amount = res.data.wallet.bonus_wallet.balance_amount;
                if (res.data.wallet.bonus_wallet.hasOwnProperty('expiration_date')) {
                  this.notifications.expiration_date = res.data.wallet.bonus_wallet.expiration_date;
                } else if (this.notifications.hasOwnProperty('expiration_date')) {
                  delete this.notifications.expiration_date;
                }
              } else {
                this.bonusWalletExists = false; // hide indication that bonus wallet exists and area is not clickable
                if (this.notifications.hasOwnProperty('expiration_date')) {
                  delete this.notifications.expiration_date;
                }
              }
            }

            // pass all notifications in the mat-chips
            this.notifications.promotions = res.data.promotions;
            this.notifications.messages = res.data.messages;
            this.notifications.bonuses = res.data.bonuses;
            this.translations = res.data.translations; // pass translations according to language selected
            // pass all info data to the service, so that main-screen component can access them
            this.service.requestInfoData(res.data);
          } else {
            this.isLoggedIn = false;
            this.router.navigate(['error-page']);
            this.snackBar.open(this.translations['error_info'], 'OK', {
              duration: 5000,
            });
          }
        },
        (err: HttpErrorResponse) => {
          if (err instanceof Error || err.error instanceof ErrorEvent) {
            this.router.navigate(['error-page']);
          }
        }
      );
  }

  /**
   * Logout function
   */
  public logout(): void {
    this.http
      .post(this.service.serverUrl + 'logout', '')
      .pipe(
        finalize(() => {
          this.service.deleteToken();
        })
      )
      .subscribe();

    this.isLoggedIn = false;
    this.service.requestLoginStatus(this.isLoggedIn);

    if (this.router.url === '') this.getInfo();
    else this.redirectTo('');

    this.closeSSE();

    this.notifications = {
      // on logout reinitialize notifications to hide them
      promotions: 0,
      bonuses: 0,
      messages: 0,
      player_id: 0,
      player_username: 'N/A',
      fulfillment_amount: 0,
      balance_amount: 0,
      total_balance: '0',
      withdrawable_amount: '0',
    };
  }

  /**
   * retrieves stored theme from cookie if exists
   */
  public getStoredThemeFromCookie(): void {
    if (this.cookieService.check('theme')) {
      this.themeSelected = this.cookieService.get('theme');
    }
  }

  /**
   * retrieves stored language from cookie if cookie exists
   */
  public getStoredLanguageFromCookie(): void {
    if (this.cookieService.check('language')) {
      this.languageSelected = this.cookieService.get('language');
    } else {
      this.languageSelected = 'EN';
    }
    this.service.requestLanguage(this.languageSelected);
  }

  /**
   * Selects theme
   *
   * @param value number
   */
  public selectTheme(value: number): void {
    switch (value) {
      case 1:
        this.themeSelected = 'blue-theme';
        break;
      case 2:
        this.themeSelected = 'dark-pink-theme';
        break;
      case 3:
        this.themeSelected = 'blue-purple-theme';
        break;
      case 4:
        this.themeSelected = 'dark-pink-theme';
        break;
      default:
        this.themeSelected = 'blue-theme';
    }

    // store preference as cookie
    this.cookieService.set('theme', this.themeSelected);
  }

  /**
   * Selects language
   *
   * @param value string
   */
  public selectLanguage(value: string): void {
    if (value === 'EN') this.languageSelected = 'EN';
    else if (value === 'AR') this.languageSelected = 'AR';
    else this.languageSelected = 'EN';

    this.cookieService.set('language', this.languageSelected); // store preference as cookie
    this.service.requestLanguage(this.languageSelected); // pass it to the service and across the app
    this.getInfo(); // request info for new translations
  }

  /**
   * navigate to route and close the menu toggling
   *
   * @param value string
   */
  public redirectTo(value: string): void {
    this.toggleMenu(); // close the menu
    this.router.navigate([value]);
  }

  public redirectToGame(value: string): void {
    this.toggleGameMenu(); // close the game menu
    this.router.navigate([value]);
  }

  public redirectToRegister(value: string): void {
    if (this.isMenuOpen && this.toggleLogin === false) {
      this.toggleMenu();
      this.toggleLogin = true;
    } else if (this.isGameMenuOpen) {
      this.toggleGameMenu();
    }
    this.router.navigate([value]);
  }

  /**
   * navigate to Deposit of wallet component and open expansion panel
   */
  public goToDeposit(): void {
    this.service.isDeposit = true;
    this.redirectTo('wallet');
  }

  /**
   * Opens bonus wallet bar on the left
   */
  public openBonusWalletBar(): void {
    if (this.languageSelected !== 'AR' && this.bonusWalletExists) {
      // standard format on non arabic language
      if (!this.notifications.hasOwnProperty('expiration_date')) {
        // if the first digit in the string is 0, nothing happens
        this.notifier.notify('default', this.translations['bonus_wallet_balance'] + ' ' + this.notifications.balance_amount);
        this.notifier.notify(
          'default',
          this.translations['play'] + ' ' + this.notifications.fulfillment_amount + ' ' + this.translations['deposit_to_your_wallet']
        );
      } else {
        this.notifier.notify('default', this.translations['bonus_wallet_balance'] + ' ' + this.notifications.balance_amount);
        this.notifier.notify('default', this.translations['expires_at'] + ' ' + this.notifications.expiration_date);
      }
    } else if (this.bonusWalletExists) {
      // reverse format on arabic language
      if (!this.notifications.hasOwnProperty('expiration_date')) {
        // if the first digit in the string is 0, nothing happens
        this.notifier.notify('default', this.notifications.balance_amount + ' ' + this.translations['bonus_wallet_balance']);
        this.notifier.notify(
          'default',
          this.translations['deposit_to_your_wallet'] + ' ' + this.notifications.fulfillment_amount + ' ' + this.translations['play']
        );
      } else {
        this.notifier.notify('default', this.notifications.balance_amount + ' ' + this.translations['bonus_wallet_balance']);
        this.notifier.notify('default', this.notifications.expiration_date + ' ' + this.translations['expires_at']);
      }
    }
  }

  /**
   * Start listening for SSE events
   */
  public subscribeToSSE(): void {
    this.isAjaxPollingCalled = true;

    // Initial delay for retry attempts
    let retryDelay = 1000; // Start with a 1 second delay
    const maxRetryDelay = 60000; // Maximum delay is 1 minute

    // Function to make an Ajax request and handle the response
    const makeRequest = (url: string, topic: string, resolve: () => void) => {
      fetch(url, {
        method: 'GET',
        headers: {
          authorization: 'Bearer ' + this.service.getToken(),
          accept: 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 429) {
              // Too many requests, increase the delay
              retryDelay = Math.min(retryDelay * 2, maxRetryDelay);
              console.warn(`Rate limited! Retrying in ${retryDelay / 1000} seconds...`);
              setTimeout(() => makeRequest(url, topic, resolve), retryDelay);
            } else {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          } else {
            // Reset delay on successful request
            retryDelay = 1000;
            return response.json();
          }
        })
        .then((sseData) => {
          // Process the response data based on the topic
          if (topic.includes('new-message')) {
            this.handleNewMessage(sseData);
          } else if (topic.includes('wallet-change')) {
            this.handleWalletChange(sseData);
          } else if (topic.includes('pb-change')) {
            this.handlePBChange(sseData);
          }
          resolve();
        })
        .catch((error) => {
          console.error('Error in Ajax polling:', error);
          resolve();
        });
    };

    const ajaxPoll = () => {
      let token = this.service.getToken();
      const tokenData = this.service.getTokenData(token);
      let ipos = token.indexOf(',');
      if (ipos > 0) {
        token = token.substring(0, ipos);
      }

      // URLs for different topics
      const topics = [
        //this.service.serverUrl + 'sse/new-message/',
        this.service.serverUrl + 'sse/wallet-change/poll/' + token,
        //`${this.service.serverUrl}sse/pb-change/`,
      ];

      const topicPromises = topics.map(
        (topic) =>
          new Promise<void>((resolve) => {
            const url = new URL(topic).toString();
            makeRequest(url, topic, resolve);
          })
      );

      // When all requests are complete, set a timeout to call ajaxPoll again
      Promise.all(topicPromises).then(() => {
        this.ajaxPollingInterval = setTimeout(ajaxPoll, 10000);
      });
    };

    // Start the polling process with an initial call
    ajaxPoll();
  }

  // Functions to handle different types of updates
  private handleNewMessage(sseData: any): void {
    this.notifier.notify('default', this.translations['message_change']);
    this.notifications.messages = sseData.message_count;
  }

  private handleWalletChange(sseData: any): void {
    if (sseData.data.wallet.new_accounting == 1) {
      document.getElementById('languageSelector').click();
    }

    // this.notifications.total_balance = sseData.data.wallet.deposit_amount;
    document.getElementById('platform-current-balance').innerText = sseData.data.wallet.balance + ' ' + sseData.data.wallet.currency;
    this.notifications.withdrawable_amount = sseData.data.wallet.withdrawable_amount;

    if ('bonus_wallet' in sseData) {
      this.notifications.expiration_date = sseData.bonus_wallet.expiration_date;
      this.notifications.fulfillment_amount = sseData.bonus_wallet.fulfillment_amount;
      this.notifications.balance_amount = sseData.bonus_wallet.balance_amount;
      this.bonusWalletExists = true;
    } else {
      delete this.notifications.expiration_date;
      this.bonusWalletExists = false;
    }
  }

  private handlePBChange(sseData: any): void {
    if (sseData.pb_state === 'new_bonus') {
      this.notifier.notify('default', this.translations['bonus_change']);
      this.notifications.bonuses = sseData.bonus_count;
    } else {
      this.notifications.promotions = sseData.promo_count;
      this.notifier.notify('default', this.translations['bonus_change']);
      this.notifications.bonuses = sseData.bonus_count;
    }
  }

  /**
   * Sets variables related to authentication
   */
  private resetAuthVariables(): void {
    this.isLoggedIn = false;
    this.formData.username = '';
    this.formData.password = '';
    this.formData.remember = false;
  }

  private closeSSE() {
    this.isSSEcalled = false;
    try {
      this.eventSource.close();
    } catch (sseError) {
      // SSE is not open!
    }
  }

  
}

/* EVAN */
window.addEventListener('message', function (e) {
  // Get the sent data
  const balance = e.data;
  console.log( '<<<<<<<<<<< BALANCE >>>>>>>>>>>');  
  var text = document.getElementById('platform-current-balance').innerText;
  console.log( text );
  var currency = text.split(' ')[1];
  document.getElementById('platform-current-balance').innerText = balance + ' ' + currency;
  console.log( balance + ' ' + currency);
});
