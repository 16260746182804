<div class="container">
  <form [formGroup]="userPassword" (ngSubmit)="saveChanges()">
    <mat-form-field class="w-100">
      <input matInput type="password" [placeholder]="translations.current_password" formControlName="current_pass" required />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput [type]="hide ? 'password' : 'text'" [placeholder]="translations.new_password" formControlName="new_pass" required />
      <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
      <span *ngIf="userPassword.get('new_pass').dirty && userPassword.get('new_pass').errors" style="font-size: x-small; color: #ff5722">{{
        translations.invalid_password
      }}</span
      ><br />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="password" [placeholder]="translations.re_type_password" formControlName="conf_pass" required />
      <mat-error [ngClass]="userPassword.errors ? null : 'hidden'">{{ translations.password_match }}</mat-error>
    </mat-form-field>
    <div id="clickable">
      <button mat-raised-button class="w-100" type="submit" color="accent" [disabled]="userPassword.status !== 'VALID'" id="savePassword">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </form>
</div>
