<div class="container">
  <form [formGroup]="userDetails" (ngSubmit)="saveChanges()">
    <mat-form-field class="w-100">
      <input matInput type="number" [placeholder]="translations.id" formControlName="id" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.username" formControlName="username" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.first_name" formControlName="f_name" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.last_name" formControlName="l_name" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.email" formControlName="email" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.passport_number" formControlName="passport" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.birthdate" formControlName="birthdate" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.gender" formControlName="gender" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.country" formControlName="country" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.city" formControlName="city" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.address" formControlName="address" />
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="translations.phone_number" formControlName="phone" />
    </mat-form-field>
    <button mat-raised-button class="w-100" type="submit" color="accent" [disabled]="!userDetails.valid">
      <mat-icon>save</mat-icon>
    </button>
  </form>
</div>
