import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  selectedLanguage: string;

  genderSelected: string = 'Male';
  maxDate: Date;
  hide: boolean = true;

  placeholders: any = {
    create_account: 'N/A',
    username: 'N/A',
    hint: 'N/A',
    first_name: 'N/A',
    last_name: 'N/A',
    email: 'N/A',
    passport_number: 'N/A',
    birthdate: 'N/A',
    gender: 'N/A',
    male: 'N/A',
    female: 'N/A',
    other: 'N/A',
    country: 'N/A',
    city: 'N/A',
    address: 'N/A',
    phone_number: 'N/A',
    language: 'N/A',
    currency: 'N/A',
    password: 'N/A',
    confirm: 'N/A',
    description: 'N/A',
    terms_conditions: 'N/A',
    receive_info: 'N/A',
    sign_up: 'N/A',
    countries: [{ name: '', code: '', flag: '' }],
    languages: ['English', 'Arabic'],
    password_hint: { hint_1: 'N/A', hint_2: 'N/A', hint_3: 'N/A' },
  };

  userForm: FormGroup;

  private alive: boolean = true;

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private service: GlobalService,
    private http: HttpClient
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
      this.postDetails({ lang: this.selectedLanguage, details: null }); // get translations for page
    });

    this.defineMaximunAllowedDate();
  }

  ngOnInit() {
    this.userForm = this.fb.group(
      {
        new_username: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('[^\u0600-\u06FF]+')]], // deny arabic characters
        f_name: ['', Validators.required],
        l_name: ['', Validators.required],
        passport: [''],
        birthdate: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+')]],
        country: [null, Validators.required],
        gender: ['Male', Validators.required],
        city: ['', Validators.required],
        address: [''],
        phone: ['', [Validators.required, Validators.pattern('(?=.*?[^A-Za-z])(\\+)?[0-9]+')]], // can start with + and must have at least 1 digit
        language: [null, Validators.required],
        matchingPasswords: this.fb.group(
          {
            password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$')]],
            conf_password: ['', Validators.required],
          },
          { validators: this.passwordsCompare }
        ),
        terms: [false, Validators.required],
        newsletter: [true],
      },
      { validators: this.passwordUsernameCompare }
    );

    // get initial data to load translations
    this.postDetails({ lang: this.selectedLanguage, details: null });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Defines maximun allowed date
   */
  defineMaximunAllowedDate(): void {
    const today = new Date();
    this.maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDay());
  }

  /**
   * Ρeturns string from date object
   *
   * @param curDate
   * @returns string
   */
  getFormattedDate(curDate) {
    const dt = new Date(curDate);
    const day = dt.getDate();
    const month = dt.getMonth();
    const year = dt.getFullYear();
    return year.toString() + '-' + month.toString() + '-' + day.toString();
  }

  /**
   * save user details as json
   */
  saveNewUser(): void {
    // if user has not agreed to terms show snackbar
    if (!this.userForm.controls.terms.value) {
      this.snackBar.open(this.placeholders.terms_message, 'OK', { duration: 5000 });
    } else if (this.findInvalidControls().length === 1) {
      this.snackBar.open(this.placeholders.check_input, 'OK', { duration: 5000 });
    } else {
      const body = {
        lang: this.selectedLanguage,
        details: {
          username: this.userForm.controls.new_username.value,
          f_name: this.userForm.controls.f_name.value,
          l_name: this.userForm.controls.l_name.value,
          passport: this.userForm.controls.passport.value,
          birthdate: this.getFormattedDate(this.userForm.controls.birthdate.value),
          email: this.userForm.controls.email.value,
          country: this.userForm.controls.country.value,
          gender: this.userForm.controls.gender.value,
          city: this.userForm.controls.city.value,
          address: this.userForm.controls.address.value,
          phone: this.userForm.controls.phone.value,
          lang: this.userForm.controls.language.value,
          // currency : this.userForm.controls.currency.value ,
          password: this.userForm.controls.matchingPasswords['controls'].password.value,
          confirm: this.userForm.controls.matchingPasswords['controls'].conf_password.value,
          terms: this.userForm.controls.terms.value,
          newsletter: this.userForm.controls.newsletter.value,
        },
      };

      this.postDetails(body);
    }
  }

  /**
   * get / post details along with translations
   *
   * @param body
   */
  postDetails(body: any): void {
    this.http.post(this.service.serverUrl + 'create-account', JSON.stringify(body)).subscribe(
      (res: any) => {
        if (res.status === 200) {
          if (body.details) {
            this.snackBar.open(this.placeholders.success_message, 'OK', { duration: 5000 });
            this.router.navigate(['']);
          } else {
            this.placeholders = res.data.translations; // take translations, only on empty details
          }
        } else {
          this.snackBar.open(res, 'OK', { duration: 5000 });
        }
      },
      (err: HttpErrorResponse) => {
        this.service.isLoggedIn = false;
      }
    );
  }

  /**
   * Checks for invalid controls before save
   */
  findInvalidControls() {
    const invalid = [];
    const controls = this.userForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) invalid.push(name);
    }
    return invalid;
  }

  private passwordsCompare(c: AbstractControl): ValidationErrors | null {
    // Compares the 2 password fields
    const pass1 = c.get('password');
    const pass2 = c.get('conf_password');
    if (pass1.pristine || pass2.pristine) return null; // if fields have been touched - success
    if (pass1.value === pass2.value) return null; // if password are the same	- success
    return { match: true }; // validation failed
  }

  private passwordUsernameCompare(c: AbstractControl): ValidationErrors | null {
    const pass1 = c['controls'].matchingPasswords.controls['password'];
    const username = c['controls'].new_username;
    if (pass1.pristine || username.pristine) return null; // if field has been touched - success
    if (pass1.value !== username.value) return null; // if password are the same	- success
    return { same_fields: true };
  }
}
