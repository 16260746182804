/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-gaming-history',
  templateUrl: './gaming-history.component.html',
  styleUrls: ['./gaming-history.component.scss'],
})
export class GamingHistoryComponent implements OnDestroy {
  selectedLanguage: string;
  gameHistoryTitle: string = 'N/A';
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param service
   */
  constructor(private service: GlobalService) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Receives title for gaming History as received from endpoint
   *
   * @param $event
   */
  receiveTitleTranslation($event) {
    this.gameHistoryTitle = $event;
  }
}
