<div class="container">
  <mat-form-field class="w-100">
    <mat-select [placeholder]="translations.select_period" (selectionChange)="periodSelected($event)" [disabled]="disableComponent">
      <mat-option value="100">{{ translations.select_period }}</mat-option>
      <mat-option *ngFor="let period of translations.periods; let i = index" [value]="i">
        {{ period }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <label hidden>
    <input
      id="dateSelector"
      #dateSelector
      [min]="minimumAllowedDate"
      [owlDateTimeTrigger]="dt5"
      [owlDateTime]="dt5"
      [selectMode]="'range'"
    />
    <owl-date-time [pickerMode]="'dialog'" #dt5 (afterPickerClosed)="formatDateTime(dateSelector.value)"></owl-date-time>
  </label>
  <button mat-raised-button class="w-100" color="accent" (click)="saveExlusionPeriod()" [disabled]="disableComponent">
    <mat-icon>save</mat-icon>
  </button>
</div>
