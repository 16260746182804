<mat-toolbar color="accent">
  <mat-toolbar-row class="toolbar-row">
    <h4 class="positionTitle">{{ wallet.title }}</h4>
    <button mat-raised-button color="warn" class="backButton" (click)="goBack()">X</button>
  </mat-toolbar-row>
</mat-toolbar>
<!-- transactions accordion -->
<mat-accordion>
  <mat-expansion-panel class="walletPanel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ wallet.transactions.title }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ogp-transactions-history *ngIf="loadComponents" [isGamingHistory]="false">
      <mat-spinner color="primary"></mat-spinner>
    </ogp-transactions-history>
  </mat-expansion-panel>
  <!-- deposit accordion -->
  <!-- Local Version: Not Included -->
  <!-- <mat-expansion-panel [expanded]="openDeposit">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ wallet.deposit.title }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ogp-request-agent
      *ngIf="loadComponents"
      [requestType]="'deposit'"
      [currency]="currencyRequest"
      [currentAccountingRequest]="currentRequests"
      [translations]="translations"
    >
      <mat-spinner color="primary"></mat-spinner>
    </ogp-request-agent>
    <ogp-scratch-code *ngIf="loadComponents" [translations]="translations">
      <mat-spinner color="primary"></mat-spinner>
    </ogp-scratch-code> -->
  <!-- Start: Old Commented out code -->
  <!-- <ogp-credit-card *ngIf="loadComponents" [requestType]="'deposit'" [translations]="translations">
       <mat-spinner color="primary"></mat-spinner>
    </ogp-credit-card> -->
  <!-- Finish: Old Commented Out code -->
  <!-- </mat-expansion-panel> -->
  <!-- withdraw accordion -->
  <!-- Local Version: Not Included -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ wallet.withdraw.title }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ogp-request-agent
      *ngIf="loadComponents"
      [requestType]="'withdraw'"
      [currency]="currencyRequest"
      [currentAccountingRequest]="currentRequests"
      [translations]="translations"
    >
      <mat-spinner color="primary"></mat-spinner>
    </ogp-request-agent> -->
  <!-- Start: Old Commented out code -->
  <!-- <ogp-credit-card *ngIf="loadComponents" [requestType]="'withdraw'" [translations]="translations">
       <mat-spinner color="primary"></mat-spinner>
    </ogp-credit-card> -->
  <!-- Finish: Old Commented out code -->
  <!-- </mat-expansion-panel> -->
  <!-- Bonuses accordion -->
  <!-- Local Version: Not Included -->
  <!-- <mat-expansion-panel class="bonuses">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ wallet.bonuses.translations.title }}</h6>
      </mat-panel-title>
      <mat-panel-description style="position: relative">
        <mat-chip color="warn" class="Circle">{{ wallet.bonuses.notifications }}</mat-chip>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ogp-bonuses [bonuses]="wallet.bonuses" [translations]="translations" (bonusSelected)="receiveBonusSelected($event)">
      <mat-spinner color="primary"></mat-spinner>
    </ogp-bonuses>
  </mat-expansion-panel> -->
</mat-accordion>
