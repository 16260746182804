/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';
import { Location } from '@angular/common';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  settings: any = {
    title: 'N/A',
    profileDetails: 'N/A',
    idDocuments: 'N/A',
    exclusion: 'N/A',
    changePass: 'N/A',
  };

  selectedLanguage: string;
  alive: boolean = true;

  translations: any;
  playerData: any;
  disabledExlusion: boolean = false;
  loadComponents: boolean = false;

  /**
   * Constructor
   */
  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private service: GlobalService,
    private location: Location
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$
      .pipe(
        takeWhile(() => this.alive),
        filter((lang) => lang !== this.selectedLanguage)
      )
      .subscribe((lang) => {
        this.selectedLanguage = lang;
        this.requestAccountSettings();
      });
  }

  /**
   * ngOnInit lifecycle
   */
  ngOnInit() {
    this.requestAccountSettings();
  }

  /**
   * initial request that getts all the player data and translations
   */
  requestAccountSettings() {
    const body = { lang: this.selectedLanguage };

    this.http
      .post(this.service.serverUrl + 'account-settings', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.translations = res.data.translations;
            this.playerData = res.data.player;

            this.settings.title = res.data.translations.account_settings;
            this.settings.profileDetails = res.data.translations.profile_details;
            this.settings.idDocuments = res.data.translations.id_documents;
            this.settings.exclusion = res.data.translations.self_exclusion;
            this.settings.changePass = res.data.translations.change_password;

            this.disabledExlusion = false;
            if (res.data.player.activity_flag === 0) this.disabledExlusion = true;
            this.loadComponents = true;
          } else {
            this.loadComponents = false;
            this.router.navigate(['']);
            this.snackBar.open(res.data.message, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {
          this.loadComponents = false;
          if (err.status !== 401) {
            this.router.navigate(['']);
          }
        }
      );
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  goBack(): void {
    this.location.back();
  }
}
