import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit, OnDestroy {
  selectedLanguage: string;
  alive: boolean = true;
  promos: any;
  translations: any = { promotion: { activate: 'N/A', promotions: 'N/A', noData: 'N/A' } };

  constructor(private router: Router, private service: GlobalService, public snackBar: MatSnackBar, private http: HttpClient) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$
      .pipe(
        takeWhile(() => this.alive),
        filter((lang) => lang !== this.selectedLanguage)
      )
      .subscribe((lang) => {
        this.selectedLanguage = lang;
        this.getPromotions(); // request promotions to get new translations
      });
  }

  ngOnInit() {
    this.getPromotions();
  }

  /**
   * Gets promotions based on language selected
   */
  getPromotions(): void {
    const body = { lang: this.selectedLanguage, id: null };

    this.http
      .post(this.service.serverUrl + 'promotion', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.promos = res.data.promotions;
            this.translations = res.data.translations;
          } else {
            this.snackBar.open(res, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {}
      );
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * clicked on promo
   *
   * @param promoId number
   */
  selectPromo(promoId: number) {
    // if user is logged in, perform the request
    if (this.service.checkIfTokenExists() || this.service.isLoggedIn) {
      const body = { lang: this.selectedLanguage, id: promoId };

      this.http
        .post(this.service.serverUrl + 'promotion', JSON.stringify(body), {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        })
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.router.navigate(['wallet']);
            } else {
              // else redirect him to main screen and open prompt for login
              this.snackBar.open(res, 'OK', { duration: 5000 });
              this.router.navigate(['wallet']);
            }
          },
          (err: HttpErrorResponse) => {}
        );
    } else {
      this.service.requestLoginStatus(false); // open menu dropdown to login
    }
  }
}
