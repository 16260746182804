<ng-container>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="row w-100 single-message-title">
          <div class="col-4 text-start">
            <h6>
              {{
                messageData.message_type === 'received'
                  ? (messageData.message_sender | stringSlice: 8)
                  : (messageData.message_recipient | stringSlice: 8)
              }}
            </h6>
          </div>
          <div class="col-4 text-center">
            <h6>{{ messageData.message_title | stringSlice: 8 }}</h6>
          </div>
          <div class="col-4 text-center">
            <em style="font-size: smaller">{{ messageData.datetime }}</em>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-panel-description class="w-100">
      <div class="gridContainer">
        <div class="item">
          <div class="messageArea">{{ messageData.message_text }}</div>
        </div>
        <div class="item">
          <button mat-raised-button color="accent" class="btn" (click)="deleteMessage(messageData.message_id, messageData.message_type)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</ng-container>
