/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, Input, OnDestroy } from '@angular/core';
import { AddCardComponent } from '../add-card/add-card.component';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnDestroy {
  @Input() requestType: string;
  @Input() translations: any;

  selectedLanguage: string;

  creditCards: any;
  selectedCard: any;
  selectedCurrency: any;

  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param dialog
   * @param service
   */
  constructor(public dialog: MatDialog, private service: GlobalService) {
    this.creditCards = {
      amount: 'Amount',
      amountValue: '8912',
      currency: 'Currency',
      currencies: [
        { id: 0, name: 'EUR' },
        { id: 1, name: 'JPY' },
        { id: 2, name: 'GBP' },
      ],
      cards: [
        { id: 0, number: '****-****-****-5649' },
        { id: 1, number: '****-****-****-1149' },
        { id: 2, number: '****-****-****-1111' },
      ],
    };

    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * show selected card
   */
  showSelectedCard(): void {
    // alert(this.selectedCard);
  }

  /**
   * show selected currency
   */
  showSelectedCurrency(): void {
    // alert(this.selectedCurrency);
  }

  /**
   * deposit / withdraw action
   */
  componentAction(): void {}

  /**
   * open dialog to insert new card by calling the AddCardComponent
   */
  addNewCard(): void {
    const dialogRef = this.dialog.open(AddCardComponent, {
      height: '400px',
      width: '85%',
      data: {
        translations: this.translations,
      },
    });
  }
}
