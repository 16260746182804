// components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile } from 'rxjs/operators';
import { environment } from './../../environments/environment';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, OnDestroy {
  selectedLanguage: string;
  iframeURL: string;
  token: string;
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param sanitizer
   * @param service
   */
  constructor(protected sanitizer: DomSanitizer, private service: GlobalService) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * ngOnInit lifecycle
   */
  ngOnInit() {
    let token = this.service.getToken();
    // Set this.iframeURL before using it
    this.iframeURL = environment.iframeURL + '?id=' + token;
    console.log('Game component, iFrame url = ' + this.iframeURL);

    // pass variables from service
    console.log(this.service.gameSelected);
    console.log(this.service.gameRoomSelected);
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Bypass security and mark url as trusted
   *
   * @param oldURL
   */
  cleanURL(oldURL: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }
}
