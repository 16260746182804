import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-scratch-code',
  templateUrl: './scratch-code.component.html',
  styleUrls: ['./scratch-code.component.scss'],
})
export class ScratchCodeComponent implements OnInit, OnDestroy {
  @Input() translations: any;

  selectedLanguage: string;
  scratchCode: FormGroup;
  private alive: boolean = true;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private service: GlobalService,
    private http: HttpClient,
    private fb: FormBuilder
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  ngOnInit() {
    this.scratchCode = this.fb.group({
      scratchCodeInput: [null, [Validators.pattern('^[a-zA-z0-9]{12}$')]],
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  sendScratchCode(code: string) {
    const body = { scratch_code: this.scratchCode.controls.scratchCodeInput.value };

    this.http
      .post(this.service.serverUrl + 'scratch-code', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.snackBar.open(this.translations.scratch_code_success, 'OK', { duration: 5000 });
          } else {
            this.snackBar.open(this.translations.scratch_code_error, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {}
      );
  }
}
