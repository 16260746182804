import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-request-agent',
  templateUrl: './request-agent.component.html',
  styleUrls: ['./request-agent.component.scss'],
})
export class RequestAgentComponent implements OnInit, OnDestroy {
  @Input() requestType: string;
  @Input() translations: any;
  @Input() currency: string;
  @Input() currentAccountingRequest: any;

  selectedLanguage: string;
  alive: boolean = true;

  request: any;

  constructor(private http: HttpClient, public snackBar: MatSnackBar, private service: GlobalService) {
    this.request = { amount: 0, requestID: 'N/A' };

    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  ngOnInit() {
    this.request = { amount: this.getRequestAmount(), requestID: this.getRequestId() };
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Gets amount from previous request (depending on request type), if the withdraw /deposit request exists
   *
   * @returns request_amount || 0
   */
  getRequestAmount() {
    if (this.currentAccountingRequest != null) {
      const request = this.currentAccountingRequest.find((x) => x.request_type === this.requestType);
      if (request) return +request.request_amount;
      else return 0;
    } else return 0;
  }

  /**
   * Gets a single request id (depending on request type) from previous request, if the withdraw /deposit request exists
   *
   * @returns  request_id || 'N/A'
   */
  getRequestId() {
    if (this.currentAccountingRequest != null) {
      const request = this.currentAccountingRequest.find((x) => x.request_type === this.requestType);
      if (request) return request.request_id;
      else return 'N/A';
    } else return 'N/A';
  }

  // send withdraw / deposit request
  sendRequest(amountValue: string) {
    const body = { amount: parseInt(amountValue, 10) };

    this.http
      .post(this.service.serverUrl + this.requestType, JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.request.requestID = res.data.request_id;
            this.snackBar.open(this.translations.agent_request_success, 'OK', { duration: 5000 });
          } else {
            this.request.requestID = 'N/A';
            this.request.amount = 0;
            this.snackBar.open(res, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {
          this.request.requestID = 'N/A';
          this.request.amount = 0;
          let errorMsg: string;
          if (err instanceof Error || err.error instanceof ErrorEvent) {
            errorMsg = 'An error occured'; //client-side error
          } else {
            errorMsg = err.error; //server-side error
          }
          this.snackBar.open(errorMsg, 'OK', { duration: 5000 });
        }
      );
  }

  /**
   * Cancels request based on request id
   */
  cancelRequest() {
    if (this.request.requestID !== 'N/A') {
      this.http
        .delete(this.service.serverUrl + this.requestType + '/cancel/' + this.request.requestID, {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        })
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.request.requestID = 'N/A';
              this.request.amount = 0;
              this.snackBar.open(this.translations.agent_request_cancel, 'OK', { duration: 5000 });
            } else {
              this.snackBar.open(res, 'OK', { duration: 5000 });
            }
          },
          (err: HttpErrorResponse) => {}
        );
    }
  }

  // download request id locally
  // saveRequestID(amountValue:number){
  // 	if(this.request.requestID!="N/A"){

  // 		let element = document.createElement('a');
  // 		let filename = "filename";

  // 		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent("Amount requested: " + amountValue +" - Request Id: "+ this.request.requestID + " for token: "+ this.service.getToken()));
  // 		element.setAttribute('download', filename);
  // 		element.style.display = 'none';
  // 		document.body.appendChild(element);
  // 		element.click();
  // 		document.body.removeChild(element);
  // 	}
  // }
}
