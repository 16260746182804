import { Component, Input, OnInit } from '@angular/core';
import { Game } from '../game';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-game-provided',
  templateUrl: './games-by-providers.component.html',
  styleUrls: ['./games-by-providers.component.scss'],
})
export class GameProvidedComponent {
  @Input() game: Game;
  selectedLanguage: string;

  /**
   * Constructor
   *
   * @param service
   */
  constructor(private service: GlobalService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.selectedLanguage = this.service.languageSelected;
  }

  public redirectToGamePage(value: string): void {
    this.router.navigate([value]);
  }
}
