import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GlobalService implements CanActivate {
  isDeposit = false;
  isLoggedIn: boolean = false;
  languageSelected: string;
  infoData: any;
  gameSelected: string;
  gameRoomSelected: number;

  serverUrl: string = environment.serverUrl;
  sseUrl: string = environment.sseUrl;

  promptLogin$: Observable<boolean>;
  language$: Observable<string>;
  infoData$: Observable<any>;
  private loginSubject = new Subject<boolean>();
  private langSubject = new Subject<string>();
  private infoDataSubject = new Subject<any>();

  constructor(private router: Router) {
    this.promptLogin$ = this.loginSubject.asObservable();
    this.language$ = this.langSubject.asObservable();
    this.infoData$ = this.infoDataSubject.asObservable();
  }

  /**
   * allow / deny navigation
   *
   * @returns boolean
   */
  canActivate(): boolean {
    if (this.isLoggedIn && this.checkIfTokenExists()) {
      this.isLoggedIn = true;
      return true;
    }
    this.requestLoginStatus(false);
    this.router.navigate(['']);
    return false;
  }

  /**
   * Send login status across the application
   *
   * @param trigger boolean
   */
  requestLoginStatus(trigger: boolean) {
    this.isLoggedIn = trigger;
    this.loginSubject.next(trigger);
  }

  /**
   * Send info data across the application
   *
   * @param trigger boolean
   */
  requestInfoData(trigger) {
    this.infoData = trigger;
    this.infoDataSubject.next(trigger);
  }

  /**
   * Set language from header component and pass it through the app
   *
   * @param language string
   */
  requestLanguage(language: string) {
    this.languageSelected = language;
    this.langSubject.next(this.languageSelected);
  }

  /**
   * Set game, room, username and password for iframe's auto login
   *
   * @param game string
   * @param room number
   */
  setGameParametersForIFrame(game: string, room: number) {
    this.gameSelected = game;
    this.gameRoomSelected = room;
  }

  /**
   * Checks if the JWT token exists either in sessionStorage or localStorage
   *
   * @returns boolean
   */
  checkIfTokenExists(): boolean {
    const sessionStorageToken = sessionStorage.getItem('token');
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageTokenExists = sessionStorageToken !== null && sessionStorageToken !== '';
    const localStorageTokenExists = localStorageToken !== null && localStorageToken !== '';

    return sessionStorageTokenExists || localStorageTokenExists;
  }

  /**
   * Returns the JWT token either from localStorage or sessionStorage with the later having priority.
   *
   * If the token does not exist it will return null
   *
   * @returns string | null
   */
  getToken(): string | null {
    if (this.checkIfTokenExists()) {
      if (sessionStorage.getItem('token')) {
        //console.log('token form sessionStorage = '+sessionStorage.getItem('token'));
        return sessionStorage.getItem('token');
      }
      if (localStorage.getItem('token')) {
        //console.log('token form localStorage = '+localStorage.getItem('token'));
        return localStorage.getItem('token');
      }
    } else {
      //console.log('token does not exists!');
    }

    return null;
  }

  /**
   * Deletes the JWT token either from localStorage or sessionStorage with the later having priority.
   *
   * @returns void
   */
  deleteToken(): void {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
  }

  getTokenData(token): any {
    const base64Url = token.split('.')[1];
    try {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            const value = '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            return value;
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (sseError) {
      return null;
    }
  }
}
