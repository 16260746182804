<!-- header -->
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
</head>
<div [ngClass]="themeSelected">
  <div class="d-flex flex-colum">
    <mat-toolbar color="primary" class="mainNavBar">
      <!-- Game Menu Button -->
      <div class="col-2 text-start">
        <button
          class="menu"
          (click)="toggleGameMenu()"
          aria-label="Main Menu"
          id="gameMenuButton"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#gameMenuDropdown'"
          aria-expanded="false"
        >
          <svg class="menu-icon" viewBox="0 0 100 100">
            <path
              class="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path class="line line2" d="M 20,50 H 80" />
            <path
              class="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
      </div>
      <!-- wallet indicator -->
      <!-- <mat-chip *ngIf="isLoggedIn" class="icon-rectangle" color="warn" id="bonusWalletPopup">
        <span [ngClass]="bonusWalletExists ? 'notificationCircle' : 'notificationHidden'"></span>
        <mat-chip class="icon-circle" [ngClass]="bonusWalletExists ? 'pulse' : ''" (click)="openBonusWalletBar()">
          <img src="../assets/wallet.svg" alt="" />
        </mat-chip>
        <div class="row text-end">
          <span>{{ translations['total_balance'] }} :</span>
        </div>
        <div class="row text-end">
          <span>{{ notifications['total_balance'] }}</span>
        </div>
      </mat-chip> -->

      <!-- main logo -->
      <div class="col-3 text-start">
        <img id="logo" src="../assets/logo.png" alt="logo" width="50" height="50" [routerLink]="''" />
      </div>
      <!-- Join/Login Buttons -->
      <div class="col-6 text-end">
        <mat-card-content *ngIf="!isLoggedIn">
          <!-- Local Version: Not Included -->
          <!-- <button mat-button id="registerButton" data-bs-toggle="modal" [attr.data-bs-target]="'#registerModal'" (click)="doToggleJoin()">
            JOIN
          </button> -->
          <button
            mat-button
            (click)="toggleMenu()"
            id="menuButton"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#menuDropdown'"
            aria-expanded="false"
          >
            {{ toggleLogin ? 'X' : 'LOGIN' }}
          </button>
        </mat-card-content>
        <!-- Username/Balance Buttons -->
        <div class="user-container">
          <mat-card-content *ngIf="isLoggedIn">
            <div class="col">
              <button mat-button id="menuButton" (click)="toggleMenu()" data-bs-toggle="collapse" [attr.data-bs-target]="'#menuDropdown'">
                <mat-icon class="user-icon">perm_identity</mat-icon>
                {{ notifications['player_username'] }}
              </button>
            </div>
            <div class="col">
              <button
                mat-button
                class="balance"
                id="walletButton"
                (click)="toggleMenu()"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#menuDropdown'"
              >
                <mat-icon class="wallet-icon">account_balance_wallet</mat-icon>
                <span id="platform-current-balance" [innerHTML]="notifications.total_balance"></span>
              </button>
            </div>
          </mat-card-content>
        </div>
      </div>
      <!-- language selection -->
      <div class="col-1 text-end">
        <div class="dropdown">
          <a color="accent" id="languageSelector" class="dropdown-toggle" data-bs-toggle="dropdown">
            <img [src]="languageSelected === 'EN' ? '../assets/uk.svg' : '../assets/sa.svg'" alt="flag" width="20" height="20" />
          </a>
          <ul class="dropdown-menu dropdown-menu-right languageDropdown" aria-labelledby="languageSelector" aria-expanded="false">
            <li>
              <a class="dropdown-item" (click)="selectLanguage('EN')">
                <img src="../assets/uk.svg" alt="flag" width="20" height="20" /> {{ translations['english'] }}
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="selectLanguage('AR')">
                <img src="../assets/sa.svg" alt="flag" width="20" height="20" /> {{ translations['arabic'] }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </mat-toolbar>
  </div>
</div>

<!-- main body -->
<div [ngClass]="themeSelected">
  <div class="collapse navbar-collapse" id="menuDropdown">
    <mat-card color="primary">
      <!-- required login details -->
      <mat-card-content *ngIf="!isLoggedIn">
        <div *ngIf="promptLogin" class="row">
          <div class="col text-center">
            <em *ngIf="!loginFailed" style="color: #ff5722">{{ translations.please_login }}</em>
            <em *ngIf="loginFailed" style="color: #ff5722">{{ translations.login_fail }}</em>
          </div>
        </div>
        <form (submit)="login()">
          <div class="row">
            <mat-form-field>
              <input
                matInput
                type="text"
                id="username"
                #username
                placeholder="{{ translations.username }}"
                [(ngModel)]="formData.username"
                name="username"
                required
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="password"
                id="password"
                #password
                placeholder="{{ translations.password }}"
                [(ngModel)]="formData.password"
                name="password"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-checkbox color="accent" id="remember" #remember [(ngModel)]="formData.remember" name="remember">
                <label for="remember" style="margin-bottom: 0">{{ translations.remember_me }}</label>
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <small>
                <a>
                  <small>{{ translations.forgot_password }}</small>
                </a>
              </small>
            </div>
            <div class="col-6 text-end">
              <button mat-raised-button type="submit" color="accent">
                {{ translations.login }}
              </button>
            </div>
          </div>
        </form>
        <div class="row">&nbsp;</div>
        <div class="row">
          <div class="col">
            <button
              mat-raised-button
              color="accent"
              class="w-100"
              id="registerButton"
              data-bs-toggle="modal"
              (click)="toggleMenu()"
              [attr.data-bs-target]="'#registerModal'"
            >
              {{ translations.create_account }}
            </button>
          </div>
        </div>
      </mat-card-content>
      <!-- user info -->
      <mat-card-content *ngIf="isLoggedIn" id="userDetails">
        <div class="form-group">
          <div class="row">
            <div class="col-5">
              <h3>{{ notifications['player_username'] }}</h3>
              <i>{{ translations['id'] }} {{ notifications['player_id'] }}</i>
            </div>
            <div class="col-2 text-center">
              <button mat-raised-button color="warn" class="close-mobile" (click)="toggleMenu()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="col-5 text-end">
              <img src="../assets/logo.png" id="user_img" alt="user" width="40" height="40" />
            </div>
          </div>
          <!-- <div class="row">
                    <div class="col-6 text-start">
                      <div class="row">
                        <span>{{translations.total_balance}}</span>
                      </div>
                      <div class="row">
                        <i>{{notifications['total_balance']}}</i>
                      </div>
                    </div>
                    <div class="col-6 text-end">
                      <div class="row">
                        <div class="col">
                            {{translations.withdrawable}} <br>
                            <i>{{notifications['withdrawable_amount']}}</i>
                        </div>
                      </div>
                    </div>
                  </div> -->
          <div class="row">
            <div class="grid-container">
              <!-- Local Version: Not Included -->
              <!-- <div class="grid-item">{{ translations.total_balance }}</div> -->
              <!-- Local Version: Not Included -->
              <!-- <div class="grid-item">{{ translations.withdrawable }}</div> -->
              <!-- Extra div for Local Version -->
              <div class="grid-item"></div>
              <!-- Local Version: Not Included -->
              <!-- <div class="grid-item"><span id="platform-current-balance" [innerHTML]="notifications.total_balance"></span></div> -->
              <!-- Local Version: Not Included -->
              <!-- <div class="grid-item">{{ notifications['withdrawable_amount'] }}</div> -->
              <!-- Extra div for Local Version -->
              <div class="grid-item"></div>
            </div>
          </div>
          <!-- Local Version: Not Included -->
          <!-- <div class="row">
            <div class="col">
              <button mat-raised-button color="accent" class="btn w-100" (click)="goToDeposit()">
                {{ translations.deposit }}
              </button>
            </div>
          </div> -->
        </div>
      </mat-card-content>
      <hr *ngIf="isLoggedIn" />
      <!-- Wallet -->
      <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="redirectTo('wallet')">
          <div class="col-2 text-center">
            <img id="wallet_icon" src="../assets/wallet.svg" alt="wallet_icon" />
          </div>
          <div class="col-8 text-center">
            <h4>{{ translations.wallet }}</h4>
          </div>
          <div class="col-2">
            <!-- <span *ngIf="notifications.bonuses!=0" [matBadge]="notifications.bonuses" matBadgeOverlap="true">&nbsp;</span> -->
            <mat-chip *ngIf="notifications.bonuses !== 0" color="warn" class="Circle">{{ notifications.bonuses }} </mat-chip>
          </div>
        </div>
      </mat-card-content>
      <!-- <hr *ngIf="isLoggedIn" /> -->
      <!-- Promotions -->
      <!-- Local Version: Not Included -->
      <!-- <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="redirectTo('promotions')">
          <div class="col-2 text-center">
            <img id="promo_icon" src="../assets/price-tag.svg" alt="promo_icon" />
          </div>
          <div class="col-8 text-center">
            <h4>{{ translations['promotions'] }}</h4>
          </div>
          <div class="col-2"> -->
      <!-- Start: Old commented out code -->
      <!-- <span *ngIf="notifications.promotions!=0" [matBadge]="notifications.promotions" matBadgeOverlap="true">&nbsp;</span> -->
      <!-- Finish: Old commented out code -->
      <!-- <mat-chip [hidden]="notifications.promotions === 0" color="warn" class="Circle">{{ notifications.promotions }}</mat-chip>
          </div>
        </div>
      </mat-card-content> -->
      <hr *ngIf="isLoggedIn" />
      <!-- Account Settings -->
      <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="redirectTo('account-settings')">
          <div class="col-2 text-center">
            <img id="promo_icon" src="../assets/gear.svg" alt="promo_icon" />
          </div>
          <div class="col-8 text-center">
            <h4>{{ translations.accounting_settings }}</h4>
          </div>
          <div class="col-2"></div>
        </div>
      </mat-card-content>
      <!-- <hr *ngIf="isLoggedIn" /> -->
      <!-- Messages -->
      <!-- Local Version: Not Included -->
      <!-- <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="redirectTo('messages')">
          <div class="col-2 text-center">
            <img id="msg_icon" src="../assets/envelope.svg" alt="message_icon" />
          </div>
          <div class="col-8 text-center">
            <h4>{{ translations.messages }}</h4>
          </div>
          <div class="col-2"> -->
      <!-- <span *ngIf="notifications.messages!=0" [matBadge]="notifications.messages" matBadgeOverlap="true">&nbsp;</span> -->
      <!-- <mat-chip *ngIf="notifications.messages !== 0" color="warn" class="Circle">{{ notifications.messages }} </mat-chip>
          </div>
        </div>
      </mat-card-content> -->
      <!-- <hr *ngIf="isLoggedIn" /> -->
      <!-- Gaming history -->
      <!-- Local Version: Not Included -->
      <!-- <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="redirectTo('gaming-history')">
          <div class="col-2 text-center">
            <img id="promo_icon" src="../assets/history.svg" alt="promo_icon" />
          </div>
          <div class="col-8 text-center">
            <h4>{{ translations.gaming_history }}</h4>
          </div>
          <div class="col-2"></div>
        </div>
      </mat-card-content> -->
      <hr />
      <!-- Theme selection -->
      <mat-card-content>
        <div class="row">
          <div class="col"></div>
          <div class="col text-center">
            <div class="dropdown">
              <button
                mat-raised-button
                color="accent"
                class="btn w-100 dropdown-toggle"
                type="button"
                id="themeSelector"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ translations['theme'] }}
              </button>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="themeSelector">
                <li>
                  <a class="dropdown-item" (click)="selectTheme(1)">{{ translations['light_blue'] }}</a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="selectTheme(2)">{{ translations['dark_pink'] }}</a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="selectTheme(3)">{{ translations['dark_purple'] }}</a>
                </li>
                <!-- <a class="dropdown-item" (click)="selectTheme(4)">Dark Pink</a> -->
              </ul>
            </div>
          </div>
        </div>
      </mat-card-content>
      <hr *ngIf="isLoggedIn" />
      <!-- About -->
      <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="redirectTo('about')">
          <div class="col-4">
            <img id="about_icon" src="../assets/information.svg" alt="about_icon" />
          </div>
          <div class="col-4 text-center">
            <h4>{{ translations['about'] }}</h4>
          </div>
          <div class="col-4"></div>
        </div>
      </mat-card-content>
      <!-- Logout -->
      <hr *ngIf="isLoggedIn" />
      <mat-card-content *ngIf="isLoggedIn">
        <div class="row" (click)="logout()">
          <div class="col-4">
            <img id="about_icon" src="../assets/logout.svg" alt="about_icon" />
          </div>
          <div class="col-4 text-center">
            <h4>{{ translations.logout }}</h4>
          </div>
          <div class="col-4"></div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- main body of game menu -->
  <div [ngClass]="themeSelected">
    <div class="collapse navbar-collapse" id="gameMenuDropdown">
      <mat-card color="primary">
        <!-- Local Version: Not Included -->
        <!-- <mat-card-content>
          <div class="row" (click)="redirectToGame('live-casino-games')">
            <div class="col-4">
              <img id="about_icon" src="../assets/suit-spade.svg" alt="about_icon" />
            </div>
            <div class="col-4 text-center">
              <h4>{{ translations.live_casino }}</h4>
            </div>
            <div class="col-4"></div>
          </div>
        </mat-card-content>
        <hr /> -->
        <mat-card-content>
          <div class="row" (click)="redirectToGame('lottery-games')">
            <div class="col-4">
              <img id="about_icon" src="../assets/hurricane.svg" alt="about_icon" />
            </div>
            <div class="col-4 text-center">
              <h4>{{ translations.live_casino }}</h4>
            </div>
            <div class="col-4"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!-- <div class="modal-header text-center">
          <h5 class="modal-title w-100" id="exampleModalLongTitle">Modal title</h5>
          <button mat-raised-button class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->

        <ogp-signup></ogp-signup>
      </div>
    </div>
  </div>

  <!-- darken background -->
  <div class="darken" (click)="toggleMenu()"></div>
  <div class="darken2" (click)="toggleGameMenu()"></div>
  <div id="contentBody">
    <router-outlet (activate)="getInfo()"></router-outlet>
  </div>
  <!-- toast notifications for bonus wallet -->
  <notifier-container></notifier-container>
</div>
