<mat-toolbar color="accent">
  <mat-toolbar-row>
    <h4 class="positionTitle">{{ settings.title }}</h4>
    <button mat-raised-button color="warn" class="backButton" (click)="goBack()">X</button>
  </mat-toolbar-row>
</mat-toolbar>
<mat-accordion>
  <!-- Profile Details accordion -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ settings.profileDetails }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ogp-profile-details *ngIf="loadComponents" [profileData]="playerData" [translations]="translations">
      <mat-spinner color="primary"></mat-spinner>
    </ogp-profile-details>
  </mat-expansion-panel>
  <!-- ID Documents accordion -->
  <!-- <mat-expansion-panel>
		<mat-expansion-panel-header>
		  <mat-panel-title>
		    <h6>{{settings.idDocuments}}</h6>
		  </mat-panel-title>
		</mat-expansion-panel-header>
		<ogp-id-documents *ngIf="loadComponents" [translations]="translations">
			<mat-spinner color="primary"></mat-spinner>
		</ogp-id-documents>
	</mat-expansion-panel> -->
  <!-- Self Exlusion accordion -->
  <!-- Local Version: Not Included -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ settings.exclusion }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ogp-self-exclusion *ngIf="loadComponents" [translations]="translations" [disableComponent]="disabledExlusion">
      <mat-spinner color="primary"></mat-spinner>
    </ogp-self-exclusion>
  </mat-expansion-panel> -->
  <!-- Change password accordion -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ settings.changePass }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ogp-change-password *ngIf="loadComponents" [translations]="translations">
      <mat-spinner color="primary"></mat-spinner>
    </ogp-change-password>
  </mat-expansion-panel>
</mat-accordion>
