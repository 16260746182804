<mat-toolbar color="accent">
  <mat-toolbar-row>
    <h4 class="positionTitle">{{ gameHistoryTitle }}</h4>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card color="primary">
  <mat-card-content>
    <ogp-transactions-history [isGamingHistory]="true" (gamingHistoryTitle)="receiveTitleTranslation($event)"
      >Loading...</ogp-transactions-history
    >
  </mat-card-content>
</mat-card>
