<mat-card color="accent" role="group" *ngFor="let bonus of bonuses.data; let i = index">
  <section *ngIf="bonuses">
    <div class="row">
      <div class="col-4 text-center">
        <img src="../../assets/logo.png" height="50" alt="{{ bonus.bonus_type }}" />
        <h6>{{ bonus.bonus_name }}</h6>
      </div>
      <div class="col-8">
        <div class="description">
          <textarea matInput>{{ bonus.description }}</textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button class="btn w-100" color="accent" type="button" (click)="selectBonus(bonus.bonus_id)">
          {{ translations.bonuses.activate | uppercase }}
        </button>
      </div>
    </div>
  </section>
  <section *ngIf="!bonuses">
    {{ translations.bonuses.noData }}
  </section>
</mat-card>
