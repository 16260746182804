import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

// modules
import { MaterialModule } from './shared/material.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { NgChartsModule } from 'ng2-charts';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// services
import { GlobalService } from './shared/global-service.service';
import { CookieService } from 'ngx-cookie-service';
import { AppInterceptor } from './http.intereptor';

// pipes
import { MessageFilterPipe } from './shared/message-filter.pipe';

// components
import { AboutComponent } from './about/about.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { SignupComponent } from './signup/signup.component';
import { MainScreenComponent } from './main-screen/main-screen.component';
import { GameRoomComponent } from './game-room/game-room.component';
import { GameComponent } from './game/game.component';
import { WalletComponent } from './wallet/wallet.component';
import { TransactionsHistoryComponent } from './transactions-history/transactions-history.component';
import { BonusesComponent } from './bonuses/bonuses.component';
import { ScratchCodeComponent } from './scratch-code/scratch-code.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { RequestAgentComponent } from './request-agent/request-agent.component';
import { AddCardComponent } from './add-card/add-card.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { IdDocumentsComponent } from './id-documents/id-documents.component';
import { SelfExclusionComponent } from './self-exclusion/self-exclusion.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MessagesComponent } from './messages/messages.component';
import { SingleMessageComponent } from './single-message/single-message.component';
import { GamingHistoryComponent } from './gaming-history/gaming-history.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LiveCasinoGamesComponent } from './live-casino-games/live-casino-games.component';
import { LotteryGamesComponent } from './lottery-games/lottery-games.component';
import { GameProvidedComponent } from './games-by-providers/games-by-providers.component';
import { GamePageComponent } from './game-page/game-page.component';

// set up custom formatfor material design datepicker
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CachedSrcDirective } from '../cached-src.directive';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left', // 'left' | 'middle' | 'right'
      distance: 10,
    },
    vertical: {
      position: 'top',
      distance: 55,
      gap: 5, // Defines the vertical gap, existing between multiple notifications (in px)
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false, // 'hide' | false
    onMouseover: 'pauseAutoHide', // 'pauseAutoHide' | 'resetAutoHide' | false
    showDismissButton: false,
    stacking: 2, // Defines whether multiple notification will be stacked, and how high the stack limit is
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      //  Defines how long it will take to shift a notification around (in ms)
      speed: 300,
      easing: 'ease',
    },
    overlap: 150, // Defines the overall animation overlap, allowing for much smoother looking animations (in ms)
  },
};
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    PromotionsComponent,
    SignupComponent,
    MainScreenComponent,
    GameRoomComponent,
    GameComponent,
    WalletComponent,
    TransactionsHistoryComponent,
    BonusesComponent,
    ScratchCodeComponent,
    CreditCardComponent,
    RequestAgentComponent,
    AddCardComponent,
    AccountSettingsComponent,
    ProfileDetailsComponent,
    IdDocumentsComponent,
    SelfExclusionComponent,
    ChangePasswordComponent,
    MessagesComponent,
    SingleMessageComponent,
    MessageFilterPipe,
    GamingHistoryComponent,
    LiveCasinoGamesComponent,
    LotteryGamesComponent,
    GameProvidedComponent,
    GamePageComponent,
    ErrorPageComponent,
    CachedSrcDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUsefulSwiperModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    RouterModule.forRoot(
      [
        { path: 'about', component: AboutComponent },
        { path: 'promotions', component: PromotionsComponent },
        { path: 'signup', component: SignupComponent },
        {
          path: 'wallet',
          component: WalletComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'messages',
          component: MessagesComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'gaming-history',
          component: GamingHistoryComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'account-settings',
          component: AccountSettingsComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'game-room/:game',
          component: GameRoomComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'lottery/:lottery',
          component: GameRoomComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'game-room/:game/table/:table',
          component: GameComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'lottery/:lottery/table/:table',
          component: GameComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'live-casino-games',
          component: LiveCasinoGamesComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'lottery-games',
          component: LotteryGamesComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'games-by-providers',
          component: GameProvidedComponent,
          canActivate: [GlobalService],
        },
        {
          path: 'game-page',
          component: GamePageComponent,
          canActivate: [GlobalService],
        },
        { path: '', component: MainScreenComponent },
        { path: '**', component: ErrorPageComponent },
      ],
      { useHash: true }
    ),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgChartsModule,
  ],
  providers: [
    GlobalService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  entryComponents: [AddCardComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
