// components
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Provider } from '../provider';

// services
import { GlobalService } from '../shared/global-service.service';
import { Game } from '../game';

@Component({
  selector: 'ogp-live-casino-games',
  templateUrl: './live-casino-games.component.html',
  styleUrls: ['./live-casino-games.component.scss'],
})
export class LiveCasinoGamesComponent implements OnInit, OnDestroy {
  selectedLanguage: string;
  games: Game[];
  providers: Provider[];
  filteredGames: Game[];
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param service
   */
  constructor(private service: GlobalService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.selectedLanguage = this.service.languageSelected;
  }

  public ngOnInit(): void {
    this.games = [
      {
        id: 1,
        imageUrl: 'https://via.placeholder.com/300x150/FF0000/FFFFFF?text=1',
        title: 'Game 1',
        providers: [1],
        route: 'game-page',
      },
      {
        id: 2,
        imageUrl: 'https://via.placeholder.com/300x150/FF0000/FFFFFF?text=1',
        title: 'Game 1a',
        providers: [2],
        route: 'game-page',
      },
      {
        id: 3,
        imageUrl: 'https://via.placeholder.com/300x150/FF0000/FFFFFF?text=1',
        title: 'Game Both',
        providers: [1, 2],
        route: 'game-page',
      },
      {
        id: 4,
        imageUrl: 'https://via.placeholder.com/300x150/FF0000/FFFFFF?text=1',
        title: 'Game 2',
        providers: [1],
        route: 'game-page',
      },
    ];
    this.providers = [
      {
        id: 1,
        title: 'Plaython',
      },
      {
        id: 2,
        title: 'Provider 2',
      },
    ];
    this.filteredGames = [...this.games];
  }

  filterGamesByProvider(provider: Provider) {
    this.filteredGames = this.games.filter((game: Game) => game.providers.includes(provider.id));
  }

  reset() {
    this.filteredGames = [...this.games];
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }
}
