<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{ translations.request_agent }}
  </mat-expansion-panel-header>
  <div class="container">
    <mat-panel-description>
      <div class="myContainer">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100">
              <input matInput [placeholder]="translations.amount" type="number" [(ngModel)]="request.amount" class="right-align" #amount />
              <span matPrefix>{{ currency }}&nbsp;</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <mat-form-field class="w-100">
              <input matInput type="text" [placeholder]="translations.request_id" value="{{ request.requestID }}" disabled />
              <mat-icon matSuffix>perm_identity</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-4">
            <button
              mat-raised-button
              id="saveRequestID"
              class="w-100"
              color="accent"
              (click)="cancelRequest()"
              [disabled]="request.requestID === 'N/A' ? true : false"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              mat-raised-button
              color="accent"
              class="btn w-100 bottomSpace"
              (click)="sendRequest(amount.value)"
              [disabled]="request.requestID === 'N/A' ? false : true"
            >
              {{ translations.request }}
            </button>
          </div>
        </div>
      </div>
    </mat-panel-description>
  </div>
</mat-expansion-panel>
