// components
import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-lottery-games',
  templateUrl: './lottery-games.component.html',
  styleUrls: ['./lottery-games.component.scss'],
})
export class LotteryGamesComponent implements OnDestroy {
  selectedLanguage: string;
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param service
   */
  constructor(private service: GlobalService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }
}
