<mat-toolbar color="accent">
  <mat-toolbar-row>
    <button mat-raised-button color="warn" class="close" data-bs-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="positionTitle">{{ placeholders.create_account }}</h4>
  </mat-toolbar-row>
</mat-toolbar>

<mat-card role="group" class="form-container">
  <form [formGroup]="userForm" (ngSubmit)="saveNewUser()">
    <!-- username field -->
    <mat-form-field class="w-100" [hintLabel]="placeholders.hint">
      <input
        color="accent"
        matInput
        type="text"
        maxlength="20"
        [placeholder]="placeholders.username"
        formControlName="new_username"
        required
      />
      <mat-hint align="end">{{ userForm.controls.new_username.value?.length || 0 }}/20</mat-hint>
    </mat-form-field>
    <!-- first name field -->
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="placeholders.first_name" formControlName="f_name" required />
    </mat-form-field>
    <!-- last name field -->
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="placeholders.last_name" formControlName="l_name" required />
    </mat-form-field>
    <!-- email field -->
    <mat-form-field class="w-100">
      <input matInput type="email" [placeholder]="placeholders.email" formControlName="email" required />
    </mat-form-field>
    <!-- passport number field -->
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="placeholders.passport_number" formControlName="passport" />
    </mat-form-field>
    <!-- birthdate field -->
    <mat-form-field class="w-100">
      <input
        matInput
        [matDatepicker]="picker"
        [max]="maxDate"
        [placeholder]="placeholders.birthdate"
        formControlName="birthdate"
        (focus)="picker.open()"
        (click)="picker.open()"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <!-- gender field -->
    <mat-radio-group class="w-100" formControlName="gender">
      <div class="row">
        <div class="col">
          <label>{{ placeholders.gender }}</label>
        </div>
        <div class="col">
          <mat-radio-button value="Male" checked>{{ placeholders.male }}</mat-radio-button>
          <mat-radio-button value="Female">{{ placeholders.female }}</mat-radio-button>
          <mat-radio-button value="Other">{{ placeholders.other }}</mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
    <!-- country field -->
    <mat-form-field class="w-100">
      <mat-select [placeholder]="placeholders.country" formControlName="country" required>
        <mat-option *ngFor="let countryObj of placeholders.countries" [value]="countryObj.code">
          <img [src]="countryObj.flag" alt="{{ countryObj.name }}" class="dropdownCountryIcon" />
          <span class="dropdownCountryName"> {{ countryObj.name }} </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- city field -->
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="placeholders.city" formControlName="city" required />
    </mat-form-field>
    <!-- address field -->
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="placeholders.address" formControlName="address" />
    </mat-form-field>
    <!-- phone field -->
    <mat-form-field class="w-100">
      <input matInput type="text" [placeholder]="placeholders.phone_number" formControlName="phone" autocomplete="tel" required />
    </mat-form-field>
    <!-- language field -->
    <mat-form-field class="w-100">
      <mat-select [placeholder]="placeholders.language" formControlName="language" required>
        <mat-option *ngFor="let language of placeholders.languages" [value]="language">
          {{ language }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- password field -->
    <div formGroupName="matchingPasswords">
      <mat-form-field class="w-100">
        <input
          matInput
          minlength="4"
          [type]="hide ? 'password' : 'text'"
          [placeholder]="placeholders.password"
          formControlName="password"
          required
        />
        <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
        <span
          *ngIf="userForm.get('matchingPasswords').get('password').dirty && userForm.get('matchingPasswords').get('password').errors"
          style="font-size: x-small; color: #ff5722"
          >{{ placeholders.password_hint.hint_1 }}</span
        ><br />
        <span *ngIf="userForm.errors && !userForm.valid" style="font-size: x-small; color: #ff5722">{{
          placeholders.password_hint.hint_2
        }}</span>
      </mat-form-field>
      <!-- confirm password field -->
      <mat-form-field class="w-100">
        <input matInput type="password" [placeholder]="placeholders.confirm" formControlName="conf_password" required />
        <span *ngIf="userForm.get('matchingPasswords').errors" style="font-size: x-small; color: #ff5722">{{
          placeholders.password_hint.hint_3
        }}</span>
      </mat-form-field>
    </div>
    <div class="description">
      <small>{{ placeholders.description }}</small>
    </div>
    <br />
    <mat-checkbox formControlName="terms" required>
      <small
        ><em>{{ placeholders.terms_conditions }}</em></small
      > </mat-checkbox
    ><br />
    <mat-checkbox formControlName="newsletter">
      <small
        ><em>{{ placeholders.receive_info }}</em></small
      >
    </mat-checkbox>
    <button mat-raised-button type="submit" class="btn w-100" color="accent" [disabled]="!userForm.valid">
      {{ placeholders.sign_up }}
    </button>
  </form>
</mat-card>
