/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-id-documents',
  templateUrl: './id-documents.component.html',
  styleUrls: ['./id-documents.component.scss'],
})
export class IdDocumentsComponent implements OnInit, OnDestroy {
  @Input() translations: any;
  @ViewChild('imageID', { static: true }) fileInput: ElementRef;

  selectedLanguage: string;
  alive: boolean = true;

  fileForm: FormGroup;
  loading: boolean = false;

  fileObject: any = { name: null, type: null, value: null };

  /**
   * Constructor
   *
   * @param service
   * @param fb
   * @param snackBar
   */
  constructor(
    private http: HttpClient,
    private service: GlobalService,
    private router: Router,
    private fb: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * ngOnInit lifecycle
   */
  ngOnInit() {
    this.fileForm = this.fb.group({
      file: [null, Validators.required],
    });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * @param $event
   */
  onFileChange($event): void {
    // convert images from the users file system
    // Check: https://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
    const file = $event.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.fileObject = {
          name: file.name,
          type: file.type,
          value: reader.result,
        };
      };
    }
    this.saveFile();
  }

  /**
   * save image file
   */
  saveFile(): void {
    this.loading = true;
    const body = { file: this.fileObject };

    this.http
      .post(this.service.serverUrl + 'id-documents', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          let msg: string;
          if (res.status === 200) {
            this.loading = false;
            msg = 'Image succesfully uploaded!';
          } else {
            this.loading = false;
            msg = 'Error: Action cancelled! Please try again.';
          }
          this.snackBar.open(msg, 'OK', { duration: 5000 });
        },
        (err: HttpErrorResponse) => {
          let errorMsg: string;
          this.loading = false;
        }
      );
  }
}
