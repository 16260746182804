<!-- Header -->
<mat-toolbar color="accent">
  <mat-toolbar-row>
    <h4 class="positionTitle">Live Casino Games</h4>
  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar-row>
  <h3 class="positionTitle">Providers</h3>
</mat-toolbar-row>

<!-- Providers -->
<div class="providersContainer">
  <div *ngFor="let provider of providers">
    <button mat-raised-button class="button" type="button" (click)="filterGamesByProvider(provider)">{{ provider.title }}</button>
  </div>
  <button mat-raised-button type="button" (click)="reset()">Reset</button>
</div>

<mat-toolbar-row>
  <h3 class="positionTitle">Games</h3>
</mat-toolbar-row>
<!-- Games by Providers -->
<div class="list-games">
  <div class="game">
    <div class="gamesContainer">
      <ogp-game-provided *ngFor="let game of filteredGames" [game]="game"> </ogp-game-provided>
    </div>
  </div>
</div>
