import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';
import { format, subDays } from 'date-fns';
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-transactions-history',
  templateUrl: './transactions-history.component.html',
  styleUrls: ['./transactions-history.component.scss'],
})
export class TransactionsHistoryComponent implements OnInit, OnDestroy {
  @Input() isGamingHistory: boolean;
  @Output() gamingHistoryTitle = new EventEmitter<string>();

  totalData: any = [];
  tableData: any = [];

  currentPage: number = 1;
  itemsPerPage: number = 30;
  disableNext: boolean = true;
  disablePrevious: boolean = true;

  filters: any = [];
  categories: any = [];
  games: any = [];
  periods: any;
  periodSel: string = '';
  defaultPeriod: any;

  noresults: string = 'N/A';
  translations: any = { noresults: 'N/A', range_error_message: 'N/A', error_message: 'N/A', period_selected: 'N/A' };
  fixedHeader: any = { datetime: 'Date', type_id: 'Type', amount: 'Amount', currency: 'Currency', balance: 'Balance' };

  periodsPlaceholder: string = 'N/A';
  filterPlaceholder: string = 'N/A';
  gamePlaceholder: string = 'N/A';
  categoryPlaceholder: string = 'N/A';

  gameSelected: string = null;
  categorySelected: string = null;
  filterSelected: string = null;

  reloadChart: boolean = false;
  doughnutChartLabels: string[] = [];
  doughnutChartData: any[] = [];

  dateFrom: Date;
  dateTo: Date;
  transactionType: string = 'all'; // Add property for transaction type

  private selectedLanguage: string;
  private alive: boolean = true;
  private playerId: number | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBar: MatSnackBar,
    private service: GlobalService,
    private zone: NgZone
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$
      .pipe(
        takeWhile(() => this.alive),
        filter((lang) => lang !== this.selectedLanguage)
      )
      .subscribe((lang) => {
        this.selectedLanguage = lang;
        this.requestTransactionHistoryForPeriod(this.periodSel);
      });
  }

  ngOnInit() {
    const now = new Date(Date.now());

    this.dateFrom = now;
    this.dateTo = now;

    this.periodSel = format(now, 'yyyy-MM-dd') + ' ~ ' + format(now, 'yyyy-MM-dd');

    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getPlayerIdAndLoadTransactions() {
    this.getPlayerId()
      .then((playerId: number) => {
        this.playerId = playerId;
        this.requestTransactionHistoryForPeriod(this.periodSel);
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  getPlayerId(): Promise<number> {
    const url = `${this.service.serverUrl}/transaction-history`;
    return this.http
      .get<{ playerId: number }>(url)
      .toPromise()
      .then((response) => response.playerId);
  }

  filterByDate() {
    if (this.dateFrom && this.dateTo) {
      const formattedDateFrom = format(this.dateFrom, 'yyyy-MM-dd');
      const formattedDateTo = format(this.dateTo, 'yyyy-MM-dd');
      this.periodSel = `${formattedDateFrom} ~ ${formattedDateTo}`;
      this.requestTransactionHistoryForPeriod(this.periodSel);

      // Display the selected dates in a snack bar
      const snackBarMessage = `Selected dates: ${formattedDateFrom} to ${formattedDateTo}`;
      this.snackBar.open(snackBarMessage, 'OK', { duration: 5000 });
    } else {
      this.snackBar.open(this.translations.range_error_message, 'OK', { duration: 5000 });
    }
  }

  filterByType() {
    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  requestTransactionHistoryForPeriod(periodPassed: string) {
    this.reloadChart = true;
    const [dateFrom, dateTo] = periodPassed.split(' ~ ');

    let endpoint = 'transaction-history';
    if (this.isGamingHistory) endpoint = 'gaming-history';

    const typeFilter = this.transactionType === 'all' ? 'all' : this.transactionType;
    const url = `${this.service.serverUrl + endpoint}?q&itemsPerPage=${this.itemsPerPage}&page=${this.currentPage}&player_id=${
      this.playerId
    }&date_from=${dateFrom}&date_to=${dateTo}&type=${typeFilter}`;

    this.http.get(url).subscribe(
      (res: any) => {
        console.log('API Response:', res); // Log the response
        if (res.status === 200) {
          this.totalData = res.records || [];
          this.updateTableData();

          this.translations.noresults = res.no_results || 'No data for the selected period';
          this.translations.error_message = res.error_message || 'N/A';
          this.translations.range_error_message = res.range_error_message || 'N/A';
          this.translations.period_selected = res.period_selected || 'N/A';
          this.periodsPlaceholder = res.period?.period || 'N/A';
          this.filterPlaceholder = res.filters?.filters || 'N/A';
          this.gamePlaceholder = res.game?.game || 'N/A';
          this.categoryPlaceholder = res.game_category?.game_category || 'N/A';
          this.fixedHeader = res.table_header || {};

          this.periods = res.period?.data || [];
          this.filters = res.filters?.data || [];
          this.categories = res.game_category?.data || [];
          this.games = res.game?.data || [];

          if (this.isGamingHistory) this.gamingHistoryTitle.emit(res.gaming_history_title);

          this.getDoughnutChartData();

          this.disablePrevious = this.currentPage === 1;
          this.disableNext = this.currentPage >= res.totalPages;

          this.reloadChart = false;
        } else {
          this.handleError();
        }
      },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    );
  }

  updateTableData() {
    this.tableData = this.totalData.map((record: any) => {
      let parsedDatetime: Date;

      try {
        // If the datetime string already ends with 'Z', use it as is
        const datetimeStr = record.date.endsWith('Z') ? record.date : record.date + 'Z';
        parsedDatetime = new Date(datetimeStr);

        // Check if the datetime is invalid
        if (isNaN(parsedDatetime.getTime())) {
          throw new Error('Invalid datetime format');
        }
      } catch (e) {
        console.error('Error parsing datetime:', e);
        parsedDatetime = new Date(); // Fallback to the current date/time if parsing fails
      }

      const localDatetime = parsedDatetime.toLocaleString('en-US', { hour12: false }); // Convert to local time

      return {
        date: localDatetime,
        type: record.transaction_type,
        transaction_amount: record.transaction_amount,
        currency: record.currency,
        balance: record.balance,
      };
    });
  }

  handleError(err?: HttpErrorResponse) {
    this.reloadChart = false;
    let errorMsg = this.translations.error_message || 'An error occurred';
    if (err) {
      if (err instanceof Error || err.error instanceof ErrorEvent) {
        errorMsg = this.translations.error_message || errorMsg;
      } else {
        errorMsg = err.error || errorMsg;
      }
    }
    this.snackBar.open(errorMsg, 'OK', { duration: 5000 });
  }

  navigatePages(isNext: boolean) {
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  getDoughnutChartData() {
    this.doughnutChartLabels = this.filters.map((filter: any) => filter.value);
    this.doughnutChartData = this.totalData;
    this.reloadChart = false;
  }

  periodSelected(value: string) {
    const periodMap = {
      today: 0,
      one_week: 7,
      one_month: 30,
      three_months: 90,
      six_months: 180,
      one_year: 365,
    };
    const days = periodMap[value] || 0;
    this.periodSel = this.getPeriod(days);
    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  getPeriod(days: number) {
    const now = new Date();
    const start = format(subDays(now, days), 'yyyy-MM-dd');
    const end = format(now, 'yyyy-MM-dd');
    return `${start} ~ ${end}`;
  }

  getGameSelected(game: string) {
    this.gameSelected = game;
    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  getCategorySelected(category: string) {
    this.categorySelected = category;
    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  getFilterSelected(filterSelected: string) {
    this.filterSelected = filterSelected;
    this.requestTransactionHistoryForPeriod(this.periodSel);
  }

  formatDateTime(value: string) {
    const [startDate, endDate] = value.split(' ~ ');
    if (startDate && endDate) {
      this.periodSel = `${format(new Date(startDate), 'yyyy-MM-dd')} ~ ${format(new Date(endDate), 'yyyy-MM-dd')}`;
      this.requestTransactionHistoryForPeriod(this.periodSel);
    }
  }

  styleTransactionsAmount(value: string) {
    return value.startsWith('+') ? 'positiveTransaction' : value.startsWith('-') ? 'negativeTransaction' : '';
  }
}
