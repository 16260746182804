import { Component, Input, OnInit, OnDestroy, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { takeWhile } from 'rxjs/operators';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { utcToZonedTime } from 'date-fns-tz';

// services
import { GlobalService } from '../shared/global-service.service';
import { addMonths, addYears } from 'date-fns';
@Component({
  selector: 'ogp-self-exclusion',
  templateUrl: './self-exclusion.component.html',
  styleUrls: ['./self-exclusion.component.scss'],
})
export class SelfExclusionComponent implements OnInit, OnDestroy {
  @Input() translations: any;
  @Input() disableComponent: boolean;

  selectedLanguage: string;
  periodSel: string = '';
  minimumAllowedDate: string;

  private alive: boolean = true;

  constructor(public snackBar: MatSnackBar, private service: GlobalService, private http: HttpClient, private zone: NgZone) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  ngOnInit() {
    // set minimum allowed date to be selected in custom period
    const d = new Date();
    this.minimumAllowedDate = d.toISOString();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  // format datetime and show appropriate message for custom range
  formatDateTime(value: string): void {
    const fullDate = value.split(' ');
    // if one day is selected
    if (fullDate.length === 5) {
      const newDate = format(parse(fullDate[0], 'MM/dd/yyyy,', new Date()), 'yyyy-MM-dd');
      const newTime = format(parse(fullDate[1] + fullDate[2], 'h:mm:ssaa', new Date()), 'HH:mm:ss');
      this.periodSel = newDate + ' ' + newTime + '~';
      this.zone.run(() => {
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
      });
    } else if (fullDate.length === 7) {
      // if range is selected
      const formatedStartDate = format(new Date(fullDate[0]), 'yyyy-MM-dd');
      const formatedEndDate = format(new Date(fullDate[4]), 'yyyy-MM-dd');
      const newTimeStart = format(parse(fullDate[1] + fullDate[2], 'h:mm:ssaa', new Date()), 'HH:mm:ss');
      const newTimeEnd = format(parse(fullDate[5] + fullDate[6], 'h:mm:ssaaa', new Date()), 'HH:mm:ss');
      this.periodSel = formatedStartDate + ' ' + newTimeStart + ' ~ ' + formatedEndDate + ' ' + newTimeEnd;
      this.zone.run(() => {
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
      });
    } else {
      // if no date is provided
      this.snackBar.open(this.translations.range_error_message, 'OK', { duration: 5000 });
    }
  }

  // translate string period to appropriate format
  periodSelected($event): void {
    console.log(this.translations);

    const now = new Date(Date.now());
    const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, tz);
    this.periodSel = formatInTimeZone(now, 'yyyy-MM-dd HH:mm:ss', 'UTC') + ' ~ ';

    let end;
    switch (parseInt($event.value, 10)) {
      case 0:
        end = format(addDays(new Date(), 1), 'yyyy-MM-dd HH:mm:ss');
        this.periodSel = format(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' ~ ' + end;
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
        break;
      case 1:
        end = format(addDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss');
        this.periodSel = format(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' ~ ' + end;
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
        break;
      case 2:
        end = format(addMonths(new Date(), 1), 'yyyy-MM-dd HH:mm:ss');
        this.periodSel = format(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' ~ ' + end;
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
        break;
      case 3:
        end = format(addMonths(new Date(), 3), 'yyyy-MM-dd HH:mm:ss');
        this.periodSel = format(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' ~ ' + end;
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
        break;
      case 4:
        end = format(addMonths(new Date(), 6), 'yyyy-MM-dd HH:mm:ss');
        this.periodSel = format(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' ~ ' + end;
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
        break;
      case 5:
        end = format(addYears(new Date(), 1), 'yyyy-MM-dd HH:mm:ss');
        this.periodSel = format(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' ~ ' + end;
        this.snackBar.open(this.translations.period_selected + ' ' + this.periodSel, 'OK', { duration: 5000 });
        break;
      case 6:
        const datepicker = document.querySelector('#dateSelector') as HTMLInputElement;
        datepicker.click();
        break;
      case 100:
        break;
    }
  }

  // on click save send the request
  saveExlusionPeriod(): void {
    const body = { period: this.periodSel };

    this.http
      .post(this.service.serverUrl + 'self-exclusion', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.snackBar.open(this.translations.self_exclusion_success_message, 'OK', { duration: 5000 });
          } else {
            this.snackBar.open(this.translations.self_exclusion_error_message, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {}
      );
  }
}
