<mat-toolbar color="accent">
  <mat-toolbar-row>
    <h4 class="positionTitle">{{ translations.promotion['promotions'] }}</h4>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card role="group" *ngFor="let promo of promos; let i = index" style="overflow-x: hidden">
  <section *ngIf="promos">
    <div class="row">
      <div class="col-4">
        <h6>{{ promo.bonus_name }}</h6>
      </div>
      <div class="col-8">
        <div class="description">
          <textarea matInput>{{ promo.description }}</textarea>
        </div>
      </div>
    </div>
    <div class="row wrapText">
      <div class="col-8">
        <span>
          <strong>{{ promo.promotion_code }}</strong>
        </span>
      </div>
      <div class="col-4 text-end">
        <em>{{ promo.expiration_datetime }}</em>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button class="btn w-100" color="accent" type="button" (click)="selectPromo(promo.bonus_id)">
          {{ translations.promotion['activate'] | uppercase }}
        </button>
      </div>
    </div>
  </section>
  <section *ngIf="!promos">
    {{ translations.noData }}
  </section>
</mat-card>
