<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h4 class="positionTitle">{{ translations.add_new_card }}</h4>
  </mat-toolbar-row>
</mat-toolbar>
<div class="row" style="padding-top: 20px">
  <div class="col">
    <h6>{{ translations.card_number }}</h6>
  </div>
</div>
<div class="row">
  <div class="col-3">
    <mat-form-field class="w-100">
      <input matInput #field1 type="text" pattern="\d*" maxlength="4" required />
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field class="w-100">
      <input matInput #field2 type="text" pattern="\d*" maxlength="4" required />
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field class="w-100">
      <input matInput #field3 type="text" pattern="\d*" maxlength="4" required />
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field class="w-100">
      <input matInput #field4 type="text" pattern="\d*" maxlength="4" required />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-4">
    <h6>{{ translations.expiration_date }}</h6>
  </div>
  <div class="col-4">
    <mat-form-field class="w-100">
      <mat-select [placeholder]="translations.month" #month>
        <mat-option>None</mat-option>
        <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field class="w-100">
      <mat-select [placeholder]="translations.year" #year>
        <mat-option value="null">None</mat-option>
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-8">
    <mat-form-field class="w-100">
      <input matInput #field5 type="text" [placeholder]="translations.cardholder" required />
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field class="w-100">
      <input matInput #field6 type="text" placeholder="CVC" pattern="\d*" maxlength="3" required />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col">
    <button
      mat-raised-button
      color="primary"
      class="w-100"
      (click)="addCard(field1.value, field2.value, field3.value, field4.value, month.value, year.value, field5.value, field6.value)"
    >
      {{ translations.add_new_card }}
    </button>
  </div>
</div>
