/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  selectedLanguage: string;

  // page translations
  translations: any = { page_not_found: 'N/A', error_text: 'N/A' };

  // flag for observables
  private infoObservable: any;
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param service
   */
  constructor(private service: GlobalService, private location: Location, public snackBar: MatSnackBar) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  ngOnInit() {
    this.infoObservable = this.service.infoData$ // detect changes from info call
      .pipe(takeWhile(() => this.alive))
      .subscribe((info) => {
        if (info) {
          this.translations.page_not_found = info.translations.page_not_found;
          this.translations.error_text = info.translations.error_text;
        } else {
          this.snackBar.open(this.translations.error_info, 'OK', { duration: 5000 });
        }
      });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * Go back to previous page
   */
  goBack() {
    this.location.back();
  }
}
