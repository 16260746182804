import { Component, Input, OnInit } from '@angular/core';
import { Game } from '../game';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../shared/global-service.service';
import { Provider } from '../provider';

@Component({
  selector: 'ogp-page',
  templateUrl: './game-page.component.html',
  styleUrls: ['./game-page.component.scss'],
})
export class GamePageComponent implements OnInit {
  @Input() game: Game;
  selectedLanguage: string;
  gameID: string | null = null;

  /**
   * Constructor
   *
   * @param service
   */
  constructor(private service: GlobalService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.selectedLanguage = this.service.languageSelected;
  }

  ngOnInit(): void {
    this.gameID = this.route.snapshot.paramMap.get('id');
  }
}
