<mat-toolbar color="accent">
  <mat-toolbar-row>
    <h4 class="positionTitle">{{ translations?.messages }}</h4>
  </mat-toolbar-row>
</mat-toolbar>
<mat-accordion>
  <!-- Inbox accordion -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ translations?.inbox }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="messages" style="max-height: 40vh; overflow: scroll">
      <ogp-single-message
        *ngFor="let message of messages.received"
        [translationsData]="translations"
        [messageData]="message"
        (deletedMessage)="receiveDeletedMessage($event)"
      ></ogp-single-message>
    </div>
  </mat-expansion-panel>
  <!-- Sent accordion -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ translations?.sent }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="messages" style="max-height: 40vh; overflow: scroll">
      <ogp-single-message
        *ngFor="let message of messages.sent"
        [translationsData]="translations"
        [messageData]="message"
        (deletedMessage)="receiveDeletedMessage($event)"
      ></ogp-single-message>
    </div>
  </mat-expansion-panel>
  <!-- New Message accordion -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h6>{{ translations?.new_message }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
        <!-- <mat-form-field>
				  <mat-select [placeholder]="translations.recipient_name" formControlName="recipient" required>
				    <mat-option *ngFor="let user of users" [value]="user">
				      {{ user }}
				    </mat-option>
				  </mat-select>
				</mat-form-field> -->
        <mat-form-field class="w-100">
          <input matInput [placeholder]="translations?.title" formControlName="title" required />
        </mat-form-field>
        <mat-form-field class="w-100">
          <textarea matInput [placeholder]="translations?.provide" formControlName="message" required></textarea>
        </mat-form-field>
        <div class="row">
          <div class="col text-end">
            <button mat-raised-button type="submit" color="accent" class="btn" [disabled]="!messageForm.valid">
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<!-- <mat-card>
  <mat-spinner *ngIf="!reloadData" style="margin: 0 auto"></mat-spinner>
</mat-card> -->
