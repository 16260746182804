<div class="container filters">
  <!-- Date Filters -->
  <div class="date-filters">
    <mat-form-field appearance="fill">
      <mat-label>Date From</mat-label>
      <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="dateFrom" />
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Date To</mat-label>
      <input matInput [matDatepicker]="pickerTo" [(ngModel)]="dateTo" />
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="filterByDate()">Search</button>
  </div>

  <!-- Type Filter -->
  <div class="type-filter">
    <mat-form-field appearance="fill">
      <mat-label>Transaction Type</mat-label>
      <mat-select [(value)]="transactionType" (selectionChange)="filterByType()">
        <mat-option value="all">All</mat-option>
        <mat-option value="deposit">Deposit</mat-option>
        <mat-option value="bet">Bet</mat-option>
        <mat-option value="win_bet">Win Bet</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>table</mat-icon>
      </ng-template>
      <div class="table-container">
        <table class="table">
          <thead class="sticky-header">
            <tr>
              <th class="text-center">Date</th>
              <th class="text-center">Type</th>
              <th class="text-center">Amount</th>
              <th class="text-center">Currency</th>
              <th class="text-center">Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let record of tableData">
              <td class="text-center">{{ record.date }}</td>
              <td class="text-center">{{ record.type }}</td>
              <td class="text-center {{ styleTransactionsAmount(record.transaction_amount) }}">
                {{ record.transaction_amount }}
              </td>
              <td class="text-center">{{ record.currency }}</td>
              <td class="text-center">{{ record.balance }}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="tableData.length === 0" class="no-results">{{ translations.noresults }}</div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="isGamingHistory">
      <ng-template mat-tab-label>
        <mat-icon>pie_chart</mat-icon>
      </ng-template>
      <div class="doughnut-chart-container">
        <ogp-doughnut-chart
          *ngIf="doughnutChartLabels"
          [labels]="doughnutChartLabels"
          [chartData]="doughnutChartData"
          [reloadChart]="reloadChart"
        ></ogp-doughnut-chart>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="container pagination-container">
  <button mat-raised-button color="primary" (click)="navigatePages(false)" [disabled]="disablePrevious">Previous</button>
  <button mat-raised-button color="primary" (click)="navigatePages(true)" [disabled]="disableNext">Next</button>
</div>
