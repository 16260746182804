<div class="container">
  <form [formGroup]="fileForm" (ngSubmit)="saveFile()">
    <div class="row">
      <div class="col-2">
        <label for="imageID">{{ translations.proof_of_id }}</label>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <input matInput type="text" [value]="fileObject.name === null ? translations.select_file : fileObject.name" disabled />
        </mat-form-field>
      </div>
      <div class="col-4">
        <button type="button" mat-raised-button class="btn w-100" color="accent" (click)="imageID.click()">
          <mat-icon>search</mat-icon>
        </button>
        <input type="file" id="imageID" formControlName="file" (change)="onFileChange($event)" #imageID />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button class="btn w-100" color="accent" type="submit" [disabled]="fileForm.invalid || loading">
          <mat-icon>cloud_upload</mat-icon>
          <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i>
        </button>
      </div>
    </div>
  </form>
</div>
