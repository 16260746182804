/**
 * Live PLatform Project
 *
 * LICENSE:
 * This document contains proprietary and copyrighted material.
 * It may not be reproduced or disclosed without the prior written
 * permission of Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions"
 * © 2017 Mahmood Hanan - Efstratios Drymalitis G.P. "Jokers Attractions".  All rights reserved.
 *
 * @package    Live PLatform
 * @department R&D
 * @PM         Dimitrios Kafetzis
 * @author     Konstantinos Blounas <kblounas@jokersattractions.com>
 * @author     Panagiotis Kirkinezis <pkirkinezis@jokersattractions.com>
 * @license
 * @version    1.0.0
 * @since      23-04-18
 */

// components
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';

@Component({
  selector: 'ogp-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Input() translations: any;

  selectedLanguage: string;

  userPassword: FormGroup;
  hide: boolean = true;
  private alive: boolean = true;

  /**
   * Constructor
   *
   * @param snackBar
   * @param fb
   * @param service
   */
  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder,
    private service: GlobalService
  ) {
    this.selectedLanguage = this.service.languageSelected;
    this.service.language$.pipe(takeWhile(() => this.alive)).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  /**
   * ngOnInit lifecycle
   */
  ngOnInit() {
    this.userPassword = this.fb.group({
      current_pass: [null, Validators.required],
      new_pass: [null, [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$')]],
      conf_pass: [null, [Validators.required, this.matchOtherValidator('new_pass')]],
    });
  }

  /**
   * ngOnDestroy lifecycle
   */
  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * save changed passwords
   */
  saveChanges() {
    if (this.userPassword.errors == null) {
      const body = {
        current_pass: this.userPassword.controls.current_pass.value,
        new_pass: this.userPassword.controls.new_pass.value,
        conf_pass: this.userPassword.controls.conf_pass.value,
      };

      this.http
        .post(this.service.serverUrl + 'change-password', JSON.stringify(body), {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        })
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              console.log('change-password success!');
              this.snackBar.open(this.translations.password_success, 'OK', { duration: 5000 });
            } else {
              console.log('change-password status: ' + res.status);
              this.snackBar.open(this.translations.password_error_message, 'OK', { duration: 5000 });
            }
          },
          (err: HttpErrorResponse) => {
            this.userPassword.reset();
          }
        );
    } else {
      this.snackBar.open(this.translations.password_error_message, 'OK', { duration: 5000 });
    }
  }

  // match controls in reactive forms using validators
  // source: https://stackoverflow.com/questions/35474991/angular-2-form-validating-for-repeat-password
  matchOtherValidator(otherControlName: string) {
    let thisControl: FormControl;
    let otherControl: FormControl;

    return (control: FormControl) => {
      if (!control.parent) return null;

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) throw new Error('matchOtherValidator(): other control is not found in parent group');
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) return null;

      if (otherControl.value !== thisControl.value) return { matchOther: true };
      return null;
    };
  }
}
