import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, takeWhile } from 'rxjs/operators';

// services
import { GlobalService } from '../shared/global-service.service';
@Component({
  selector: 'ogp-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessagesComponent implements OnInit, OnDestroy {
  selectedLanguage: string;

  users: Array<string> = [];
  messages: any = {};
  translations: any;

  reloadData: boolean;
  messageForm: FormGroup;

  private alive: boolean = true;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private service: GlobalService,
    private router: Router
  ) {
    this.selectedLanguage = this.service.languageSelected;

    this.service.language$
      .pipe(
        takeWhile(() => this.alive),
        filter((lang) => lang !== this.selectedLanguage)
      )
      .subscribe((lang) => {
        this.selectedLanguage = lang;
        this.postMessage(null); // get all messages
      });
  }

  ngOnInit() {
    // initiate an empty new message form
    this.messageForm = this.fb.group({
      title: ['', Validators.required],
      // recipient: ['', [Validators.required]],
      message: ['', Validators.required],
    });

    // get all messages
    this.postMessage(null);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  // request all messages / post new message
  postMessage(newMessage) {
    const body = { lang: this.selectedLanguage, message: newMessage };

    this.http
      .post(this.service.serverUrl + 'messages', JSON.stringify(body), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.messages = res.data.messages;
            this.users = res.data.messages.messageRecipientList;
            this.translations = res.data.translations;

            this.messages.sent.map((message: any) => {
              message['message_type'] = 'sent';
              return message;
            });
            this.messages.received.map((message: any) => {
              message['message_type'] = 'received';
              return message;
            });

            if (newMessage != null) {
              this.snackBar.open(this.translations.message_success, 'OK', { duration: 5000 });
            }
            this.messageForm.controls.title.setValue('');
            this.messageForm.controls.message.setValue('');
          } else {
            this.snackBar.open(res, 'OK', { duration: 5000 });
          }
        },
        (err: HttpErrorResponse) => {
          // clear form's data
          this.messageForm.controls.title.setValue('');
          this.messageForm.controls.message.setValue('');
        }
      );
  }

  // send new message
  sendMessage() {
    const newMessage = {
      message_title: this.messageForm.controls.title.value,
      // message_recipient: this.messageForm.controls.recipient.value,
      message_text: this.messageForm.controls.message.value,
    };

    this.postMessage(newMessage);
  }

  // remove message with id received from messages instance
  receiveDeletedMessage($event) {
    let message;

    // find which object contains the provided id
    if (this.messages[$event.type] != null) message = this.messages[$event.type].find((x) => x.message_id === $event.msgID);

    if (message) {
      // locate the object index inside the relevant array
      const index = this.messages[$event.type].indexOf(message);
      // remove message entry from messages array
      if (index !== -1) this.messages[$event.type].splice(index, 1);
    } else {
      this.snackBar.open(this.translations.error_message_delete, 'OK', { duration: 5000 });
    }
  }
}
